// src/pages/CompetitionDetailsPage.js

import React, { useState, useEffect, useMemo } from "react";
import {
  Window,
  WindowContent,
  WindowHeader,
  Avatar,
  Frame,
  Counter,
  GroupBox,
  ProgressBar,
  Button,
  NumberInput,
  Tabs,
  Tab,
  TabBody,
  Table,
  TableBody,
  TableDataCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Tooltip
} from "react95";
import { FaCopy } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import CompetitionStyles from "../styles/CompetitionStyles";
import styled from "styled-components";
import { useProgram } from "../App";
import BN from "bn.js";
import { toast } from "react-toastify"; // Import toast
import { useError } from "../context/ErrorContext";
import {
  VersionedTransaction,
  Transaction,
  sendAndConfirmTransaction,
  LAMPORTS_PER_SOL,
  SystemProgram,
  PublicKey,
} from "@solana/web3.js";
import * as web3 from "@solana/web3.js"; // If you need the entire web3 namespace

import { API_URLS, getSwapInstruction } from "@raydium-io/raydium-sdk-v2";
import bs58 from "bs58";
import { getOrCreateAssociatedTokenAccount } from "@solana/spl-token";

// Add this line to use the API_BASE_URL environment variable
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";
const { Buffer } = require("buffer");

// Predefined logos with corresponding link field names
const LOGOS = [
  { src: "/logos/solana.svg", linkField: "solana_link" },
  { src: "/logos/website.svg", linkField: "website_link" },
  { src: "/logos/telegram.svg", linkField: "telegram_link" },
  { src: "/logos/x.svg", linkField: "x_link" },
  { src: "/logos/discord.svg", linkField: "discord_link" },
];

// Function to prepend https:// if missing
const formatURL = (url) => {
  if (!url) return ""; // Return empty string if no URL provided
  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`;
  }
  return url;
};

// Function to generate a random color
const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// Styled components for logos
const LogoContainer = styled.div`
  display: flex;
  gap: 8px; /* Space between logos */
  align-items: center;
  width: 50%;
  justify-content: center;
  flex-wrap: wrap; /* Allow logos to wrap on smaller screens */
  margin-bottom: 10px; /* Space above the logos */
`;

const LogoImage = styled.img`
  width: 24px; /* Adjust size as needed */
  height: 24px;
  object-fit: contain;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.2);
  }
`;

// Rainbow effect text for displaying "LIVE" when progress is 100%
const RainbowText = styled.span`
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  font-weight: bold;
  background: linear-gradient(to right, ${({ colors }) => colors.join(", ")});
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: rainbow_animation 3s ease-in-out infinite;
  background-size: 400% 100%;

  @keyframes rainbow_animation {
    0%,
    100% {
      background-position: 0 0;
    }
    50% {
      background-position: 100% 0;
    }
  }
`;

// Styled component for glowing jackpot text
const JackpotText = styled.p`
  font-size: clamp(1rem, 2.13vw, 2.13rem);
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  background: ${({ isJackpot, colors }) =>
    isJackpot ? `linear-gradient(to right, ${colors.join(", ")})` : "inherit"};
  -webkit-background-clip: ${({ isJackpot }) =>
    isJackpot ? "text" : "inherit"};
  background-clip: ${({ isJackpot }) => (isJackpot ? "text" : "inherit")};
  color: ${({ isJackpot }) => (isJackpot ? "transparent" : "inherit")};
  animation: ${({ isJackpot }) =>
    isJackpot ? "rainbow_animation 3s ease-in-out infinite" : "none"};
  background-size: 400% 100%;

  @keyframes rainbow_animation {
    0%,
    100% {
      background-position: 0 0;
    }
    50% {
      background-position: 100% 0;
    }
  }
`;

const ProgressWrapper = styled.div`
  width: 100%;
  position: relative; /* Make the wrapper relative so the LIVE text can be absolutely positioned */
`;

const CompetitionDetailsPage = () => {
  const { id } = useParams();
  const [competition, setCompetition] = useState(null);
  const [entries, setEntries] = useState([]);
  const [remainingTime, setRemainingTime] = useState(null);
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { wallet, publicKey } = useWallet();
  const [ticketAmount, setTicketAmount] = useState(1);
  const [counterSize, setCounterSize] = useState("lg");
  const [activeTab, setActiveTab] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false); // State for confirmation window
  const [isProcessing, setIsProcessing] = useState(false); // State for processing
  const [feePercentages, setFeePercentages] = useState(null);
  const { showError } = useError();

  const { connection } = useConnection();
  const { program } = useProgram();

  const randomColors = useMemo(() => {
    return [
      getRandomColor(),
      getRandomColor(),
      getRandomColor(),
      getRandomColor(),
    ];
  }, []);

  // 1. Define calculateRemainingTime first
  const calculateRemainingTime = (endTime) => {
    const now = new Date().getTime();
    const distance = new Date(endTime).getTime() - now;

    if (distance < 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  // 2. Define fetchCompetition after calculateRemainingTime
  const fetchCompetition = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/competitions/${id}`);
      const competition = response.data;
  
      console.log("Competition PDA Address:", competition.pda_address);
      console.log("Competition Creator Address:", competition.creator_id);
  
      setCompetition(competition);
      setRemainingTime(calculateRemainingTime(competition.end_time));
    } catch (error) {
      console.error("Error fetching competition:", error);
      showError("Failed to load competition details. Please try again.");
    }
  };
  

  // 3. Define fetchEntries
  const fetchEntries = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/competitions/${id}/entries`
      );
      setEntries(response.data);
    } catch (error) {
      console.error("Error fetching entries:", error);
      showError("Failed to load competition entries. Please try again.");
    }
  };
  

  // Function to fetch user's token accounts
  const fetchTokenAccountData = async (connection, publicKey) => {
    const tokenAccounts = await connection.getTokenAccountsByOwner(publicKey, {
      programId: new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"), // SPL Token Program ID
    });

    return {
      tokenAccounts: tokenAccounts.value.map((accountInfo) => ({
        publicKey: accountInfo.pubkey.toBase58(),
        mint: new PublicKey(accountInfo.account.data.slice(0, 32)).toBase58(), // Mint address is the first 32 bytes
      })),
    };
  };

  // Helper function to ensure ATA exists
  async function ensureATA(mint, owner, connection, payer) {
    const ata = await getOrCreateAssociatedTokenAccount(
      connection,
      payer, // Fee payer for the transaction
      mint, // Mint address (e.g., USDC Mint)
      owner, // Wallet owner
      true // Allow the ATA to be created if missing
    );
    console.log(
      `Associated Token Account for ${mint.toBase58()}:`,
      ata.address.toBase58()
    );
    return ata.address;
  }

  useEffect(() => {
    const fetchProtocolConfig = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/protocol-config`);
        const feeConfigMap = response.data.reduce((acc, item) => {
          acc[item.key] = parseFloat(item.value);
          return acc;
        }, {});
  
        const protocol_fee_percentage = feeConfigMap.PROTOCOL_FEE_PERCENTAGE || 0;
        const creator_fee_percentage = feeConfigMap.CREATOR_FEE_PERCENTAGE || 0;
        const global_jackpot_fee_percentage =
          feeConfigMap.GLOBAL_JACKPOT_FEE_PERCENTAGE || 0;
        const influencer_fee_percentage =
          feeConfigMap.INFLUENCER_FEE_PERCENTAGE || 0;
  
        // Calculate the total fees percentage
        const totalFeesPercentage =
          protocol_fee_percentage +
          creator_fee_percentage +
          global_jackpot_fee_percentage +
          influencer_fee_percentage;
  
        // Set the state with the individual fees plus the total
        setFeePercentages({
          protocol_fee_percentage,
          creator_fee_percentage,
          global_jackpot_fee_percentage,
          influencer_fee_percentage,
          totalFeesPercentage,
        });
  
        console.log("Protocol Configurations:", {
          protocol_fee_percentage,
          creator_fee_percentage,
          global_jackpot_fee_percentage,
          influencer_fee_percentage,
          totalFeesPercentage,
        });
      } catch (error) {
        console.error("Error fetching protocol configuration:", error);
        showError("Failed to fetch protocol configuration. Please try again.");
      }
    };
  
    fetchProtocolConfig();
  }, [showError]);
  

  /*
  // Main executeTokenSwap function
  async function executeTokenSwap(
    connection,
    publicKey,
    signTransaction, // from useWallet()
    tokenBuyAmountLamports,
    competition
  ) {
    // 1. Check if we’re on devnet or if we want to mock the swap
    const isDevnetEndpoint =
      connection.rpcEndpoint.includes("devnet") ||
      process.env.REACT_APP_MOCK_SWAP === "true";

    // If we’re on devnet OR signTransaction is not defined,
    // short-circuit with mock success so we don’t crash.
    if (isDevnetEndpoint || !signTransaction) {
      console.log(
        "Devnet environment or no signTransaction: Mocking swap success..."
      );
      return "MockSwapSignature_1234_DEVNET";
    }

    // If not devnet, run the real Raydium swap below:
    try {
      // 2. Setup input/output mints
      const inputMint = new PublicKey(
        "So11111111111111111111111111111111111111112"
      ); // wSOL
      const outputMint = new PublicKey(competition.token); // Use competition.token

      const isInputSol = inputMint.equals(
        new PublicKey("So11111111111111111111111111111111111111112")
      );
      const isOutputSol = outputMint.equals(
        new PublicKey("So11111111111111111111111111111111111111112")
      );

      // 3. Fetch user’s token accounts
      const { tokenAccounts } = await fetchTokenAccountData(
        connection,
        publicKey
      );
      const inputTokenAcc = tokenAccounts.find(
        (a) => a.mint === inputMint.toBase58()
      )?.publicKey;
      let outputTokenAcc = tokenAccounts.find(
        (a) => a.mint === outputMint.toBase58()
      )?.publicKey;

      if (!outputTokenAcc) {
        console.log("Creating output token account...");
        outputTokenAcc = await ensureATA(
          outputMint,
          publicKey,
          connection,
          publicKey
        );
      }

      if (!inputTokenAcc && !isInputSol) {
        throw new Error("Input token account not found.");
      }

      console.log("Input Token Account:", inputTokenAcc);
      console.log("Output Token Account:", outputTokenAcc);

      // 4. Fetch Raydium priority fee
      const { data: feeData } = await axios.get(
        `${API_URLS.BASE_HOST}${API_URLS.PRIORITY_FEE}`
      );
      const computeUnitPriceMicroLamports = String(feeData.data.default.h);

      // 5. Fetch swap quote from Raydium
      const { data: swapResponse } = await axios.get(
        `${API_URLS.SWAP_HOST}/compute/swap-base-in`,
        {
          params: {
            inputMint: inputMint.toBase58(),
            outputMint: outputMint.toBase58(),
            amount: tokenBuyAmountLamports.toString(),
            slippageBps: competition.slippage * 100 || 50, // e.g., 0.5%
            txVersion: "V0", // or "LEGACY" if you prefer
          },
        }
      );

      // 6. Fetch swap transactions
      const { data: swapTransactions } = await axios.post(
        `${API_URLS.SWAP_HOST}/transaction/swap-base-in`,
        {
          computeUnitPriceMicroLamports,
          swapResponse,
          txVersion: "V0", // or "LEGACY"
          wallet: publicKey.toBase58(),
          wrapSol: isInputSol,
          unwrapSol: isOutputSol,
          inputAccount: isInputSol ? undefined : inputTokenAcc,
          outputAccount: isOutputSol ? undefined : outputTokenAcc,
        }
      );

      // 7. Deserialize each transaction
      const allTxBuf = swapTransactions.data.map((tx) =>
        Buffer.from(tx.transaction, "base64")
      );

      // If "version" is "V0", it’s versioned; if "LEGACY", it’s the older type
      const isV0Tx = swapResponse.version === "V0";

      const allTransactions = allTxBuf.map((txBuf) =>
        isV0Tx
          ? VersionedTransaction.deserialize(txBuf)
          : Transaction.from(txBuf)
      );

      // 8. Send and confirm each transaction separately
      for (let i = 0; i < allTransactions.length; i++) {
        const rawTx = allTransactions[i];
        console.log(
          `Sending transaction ${i + 1}/${allTransactions.length}...`
        );

        if (!isV0Tx) {
          // ----- Legacy Transaction flow -----
          const tx = rawTx; // cast to Transaction
          tx.feePayer = publicKey;
          const signedTx = await signTransaction(tx); // use the wallet adapter
          const txId = await connection.sendRawTransaction(
            signedTx.serialize(),
            {
              skipPreflight: false,
              preflightCommitment: "processed",
            }
          );

          // Confirm
          const confirmation = await connection.confirmTransaction(
            txId,
            "processed"
          );
          if (confirmation.value.err) {
            throw new Error(`Transaction ${i + 1} failed. Signature: ${txId}`);
          }

          console.log(`Transaction #${i + 1} confirmed. Signature: ${txId}`);
        } else {
          // ----- Versioned Transaction flow (V0) -----
          const vtx = rawTx; // cast to VersionedTransaction
          const signedVtx = await signTransaction(vtx);
          const sig = await connection.sendRawTransaction(
            signedVtx.serialize(),
            {
              skipPreflight: false,
              preflightCommitment: "processed",
            }
          );

          // Confirm
          const { blockhash, lastValidBlockHeight } =
            await connection.getLatestBlockhash();
          await connection.confirmTransaction(
            { signature: sig, blockhash, lastValidBlockHeight },
            "processed"
          );

          console.log(`Transaction #${i + 1} confirmed. Signature: ${sig}`);
        }
      }

      console.log("All Raydium swap transactions completed successfully.");
      // Return last signature or a success message
      return "RealSwapSignature_MAINNET";
    } catch (error) {
      console.error("Error during token swap:", error);
      throw new Error("Token swap failed.");
    }
  }
  */
  // 4. useEffect to fetch data
  useEffect(() => {
    fetchCompetition();
    fetchEntries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // 5. useEffect for countdown
  useEffect(() => {
    const interval = setInterval(() => {
      if (competition) {
        setRemainingTime(calculateRemainingTime(competition.end_time));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [competition]);

  // 6. useEffect for copied
  useEffect(() => {
    if (copied) {
      toast.info("Address copied to clipboard!");
      setTimeout(() => setCopied(false), 2000);
    }
  }, [copied]);

  // 7. useEffect for responsive design
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
      if (window.innerWidth <= 768) {
        setCounterSize("m");
      } else {
        setCounterSize("lg");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // 8. Loading state
  if (!competition) {
    return <div>Loading...</div>;
  }

  // 9. Helper functions
  const truncateAddress = (address) =>
    `${address.slice(0, 5)}...${address.slice(-5)}`;

  const getAddressDisplay = (address, isSmallScreen) =>
    isSmallScreen ? truncateAddress(address) : address;

  const formatTime = (time) => {
    return `${time.days.toString().padStart(2, "0")}${time.hours
      .toString()
      .padStart(2, "0")}${time.minutes
      .toString()
      .padStart(2, "0")}${time.seconds.toString().padStart(2, "0")}`;
  };

  const timeString = remainingTime ? formatTime(remainingTime) : "00000000";
  const minimumPool = competition.minimum_pool;
  const currentAmount = competition.prize_pool;
  const progressPercentage = Math.min(
    ((currentAmount / minimumPool) * 100).toFixed(1),
    100
  );

  const handleInputChange = (value) => {
    if (!isNaN(value) && value > 0) {
      setTicketAmount(value);
    } else {
      setTicketAmount(1);
    }
  };

  const handleChangeTab = (value) => {
    setActiveTab(value);
  };

  const handleClose = () => {
    if (location.state?.fromCreateCompetition) {
      // If the user came from the create-competition page, go to the home page
      navigate("/");
    } else {
      // Otherwise, go back to the previous page
      navigate(-1);
    }
  };

  const handleBuyClick = () => {
    setShowConfirmation(true); // Show confirmation window
  };
  /* ////////
  const handleConfirmBuy = async () => {
    setShowConfirmation(false);

    // 1. Ensure wallet is connected
    if (!publicKey) {
      toast.error("Please connect your wallet to buy tickets.");
      return;
    }

    setIsProcessing(true);

    try {
      // 2. Calculate your lamports needed
      const ticketPriceSOL = competition.ticket_price;
      const totalCostSOL = ticketPriceSOL * ticketAmount;
      const tokenBuyPercentage = competition.token_buy_percentage;
      const tokenBuyAmountSOL = (totalCostSOL * tokenBuyPercentage) / 100;
      const programPaymentSOL = totalCostSOL - tokenBuyAmountSOL;
      const tokenBuyAmountLamports = tokenBuyAmountSOL * web3.LAMPORTS_PER_SOL;
      const programPaymentLamports = programPaymentSOL * web3.LAMPORTS_PER_SOL;

      // 3. Optional devnet or mock check
      const isDevnetEndpoint =
        connection.rpcEndpoint.includes("devnet") ||
        process.env.REACT_APP_MOCK_SWAP === "true";

      let swapResult;
      if (isDevnetEndpoint || !signTransaction) {
        // If on devnet or user’s wallet adapter is missing signTransaction,
        // do not call executeTokenSwap.
        // Just mock a success signature here:
        console.log("Mock swap success or no signTransaction available");
        swapResult = "MockSwapSignature_1234_DEVNET";
      } else {
        // If we pass the devnet check, then we DO have signTransaction.
        // Also check user’s SOL balance if you want:
        const userBalanceLamports = await connection.getBalance(publicKey);
        const feeBufferLamports = 0.01 * web3.LAMPORTS_PER_SOL;
        const totalNeededLamports =
          tokenBuyAmountLamports + programPaymentLamports + feeBufferLamports;

        if (userBalanceLamports < totalNeededLamports) {
          toast.error(
            `Insufficient funds.
             You have ${(userBalanceLamports / web3.LAMPORTS_PER_SOL).toFixed(
               3
             )} SOL,
             need at least ${(
               totalNeededLamports / web3.LAMPORTS_PER_SOL
             ).toFixed(3)} SOL.`
          );
          setIsProcessing(false);
          return;
        }

        // 4. Call executeTokenSwap for the real swap
        swapResult = await executeTokenSwap(
          connection,
          publicKey,
          signTransaction, // guaranteed to exist here
          tokenBuyAmountLamports,
          competition
        );
      }

      // If we reach here, we have a successful swap (real or mocked)
      toast.success("Token swap successful!");
      console.log("Swap result:", swapResult);

      // 5. Notify backend for purchase_tickets
      const payload = {
        user_id: publicKey.toBase58(),
        amount: programPaymentLamports.toString(),
        ticket_count: ticketAmount,
        topOff: false,
        txSignature: swapResult,
        blockhash: "TBD",
        details: {
          ticketPriceLamports: (
            ticketPriceSOL * web3.LAMPORTS_PER_SOL
          ).toString(),
          purchaseAmount: programPaymentLamports.toString(),
          tokenBuyAmount: tokenBuyAmountLamports.toString(),
          protocolFee: (
            (programPaymentLamports * competition.protocol_fee_percentage) /
            100
          ).toString(),
          creatorFee: (
            (programPaymentLamports * competition.creator_fee_percentage) /
            100
          ).toString(),
          influencerFee: (
            (programPaymentLamports * competition.influencer_fee_percentage) /
            100
          ).toString(),
          totalFees: (
            (programPaymentLamports * competition.protocol_fee_percentage) /
              100 +
            (programPaymentLamports * competition.creator_fee_percentage) /
              100 +
            (programPaymentLamports * competition.influencer_fee_percentage) /
              100
          ).toString(),
          totalPayment: programPaymentLamports.toString(),
        },
      };

      const response = await axios.post(
        `${API_BASE_URL}/competitions/${id}/entries`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        toast.success("Tickets purchased successfully!");
        fetchCompetition();
        fetchEntries();
      } else {
        toast.error("Transaction successful, but backend logging failed.");
        console.error("Backend logging failed:", response.data);
      }
    } catch (error) {
      console.error("Error processing transaction:", error);
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(`Error buying tickets: ${error.response.data.error}`);
      } else {
        toast.error(
          error.message || "Error processing transaction. Please try again."
        );
      }
    } finally {
      setIsProcessing(false);
    }
  };
 */

  const handleConfirmBuy = async () => {
    setShowConfirmation(false);

    if (!publicKey || !wallet) {
      showError("Please connect your wallet to proceed.");
      return;
    }
  
    // Ensure `feePercentages` is loaded before proceeding
    if (!feePercentages) {
      showError("Fee percentages not loaded yet. Please try again shortly.");
      return;
    }

    setIsProcessing(true);

    try {
      //--------------------------------------
      // 1) Pull data from the local `competition` object
      //--------------------------------------
      const ticketPriceSOL = competition.ticket_price; // e.g. 0.05
      const tokenBuyPercentage = competition.token_buy_percentage; // e.g. 2 (or 200 for basis points)

      const totalCostSOL = ticketPriceSOL * ticketAmount;
      const tokenBuyAmountSOL = (totalCostSOL * tokenBuyPercentage) / 100;
      const programPaymentSOL = totalCostSOL - tokenBuyAmountSOL;
      const jackpotPaymentSOL =
        (totalCostSOL * competition.pool_percentage) / 100;

      // Convert SOL to lamports (1 SOL = 1,000,000,000 lamports)
      const tokenBuyLamports = web3.LAMPORTS_PER_SOL * tokenBuyAmountSOL;
      const programPaymentLamports = web3.LAMPORTS_PER_SOL * programPaymentSOL;

      const competitionCountBuffer = new BN(
        competition.competition_number
      ).toArrayLike(Buffer, "le", 8);

      // For logging or letting the user know:
      console.log("Ticket Price (SOL):", ticketPriceSOL);
      console.log("Total cost (SOL):", totalCostSOL);
      console.log("Token buy amount (SOL):", tokenBuyAmountSOL);
      console.log("Program payment (SOL):", programPaymentSOL);
      console.log("Jackpot Contriubtion (SOL):", jackpotPaymentSOL);

      //--------------------------------------
      // 2) Calculate fees using `feePercentages`
      //--------------------------------------
      const {
        protocol_fee_percentage,
        creator_fee_percentage,
        influencer_fee_percentage,
        global_jackpot_fee_percentage,
      } = feePercentages;

      const protocolFeeSOL = totalCostSOL * (protocol_fee_percentage / 100);
      const creatorFeeSOL = totalCostSOL * (creator_fee_percentage / 100);
      const influencerFeeSOL = totalCostSOL * (influencer_fee_percentage / 100);
      const globalJackpotFeeSOL =
        totalCostSOL * (global_jackpot_fee_percentage / 100);

      // Convert fees to strings or lamports as needed
      console.log("Fees:", {
        protocolFeeSOL,
        creatorFeeSOL,
        influencerFeeSOL,
        globalJackpotFeeSOL,
      });

      //--------------------------------------
      // 3) Derive your PDAs
      //--------------------------------------

      const competitionPDA = new web3.PublicKey(competition.pda_address);

      const [creatorPDA] = await web3.PublicKey.findProgramAddress(
        [Buffer.from("creator"), publicKey.toBuffer()],
        program.programId
      );

      const [ticketPDA] = await web3.PublicKey.findProgramAddress(
        [competitionPDA.toBuffer(), publicKey.toBuffer()],
        program.programId
      );

      const [globalJackpotPDA] = await web3.PublicKey.findProgramAddress(
        [Buffer.from("global_jackpot")],
        program.programId
      );

      const [globalJackpotTicketPDA] = await web3.PublicKey.findProgramAddress(
        [Buffer.from("global_jackpot_ticket"), publicKey.toBuffer()],
        program.programId
      );

      const [protocolFeeAccountPDA] = await web3.PublicKey.findProgramAddress(
        [Buffer.from("protocol_fee_account")],
        program.programId
      );

      const [influencerFeeAccountPDA] = await web3.PublicKey.findProgramAddress(
        [Buffer.from("influencer_fee_account")],
        program.programId
      );

      console.log("Derived PDAs:");
      console.log("Creator PDA:", creatorPDA.toBase58());
      console.log("Competition PDA:", competitionPDA.toBase58());
      console.log("Ticket PDA:", ticketPDA.toBase58());
      console.log("Global Jackpot PDA:", globalJackpotPDA.toBase58());
      console.log(
        "Global Jackpot Ticket PDA:",
        globalJackpotTicketPDA.toBase58()
      );
      console.log(
        "Protocol Fee Account PDA:",
        protocolFeeAccountPDA.toBase58()
      );
      console.log(
        "Influencer Fee Account PDA:",
        influencerFeeAccountPDA.toBase58()
      );
      console.log("Ticket Amount:", ticketAmount);

      //--------------------------------------
      // Handle `creatorPubkey` correctly
      //--------------------------------------
      if (!competition.creator_id) {
        throw new Error("Competition creator ID is missing.");
      }
      const creatorPubkey = new web3.PublicKey(competition.creator_id);
      console.log("Creator PublicKey:", creatorPubkey.toBase58());

      //----------------------------------------
      // 4) Build the purchaseTickets instruction
      //--------------------------------------
      const ticketCountBN = new BN(ticketAmount);
      console.log("TicketCountBN:", ticketCountBN);

      const transaction = new web3.Transaction();

      const instruction = await program.methods
        .purchaseTickets(ticketCountBN)
        .accounts({
          competition: competitionPDA,
          ticket: ticketPDA,
          participant: publicKey, // Signer
          protocolFeeAccount: protocolFeeAccountPDA,
          globalJackpot: globalJackpotPDA,
          influencerFeeAccount: influencerFeeAccountPDA,
          creator: creatorPDA,
          systemProgram: web3.SystemProgram.programId,
          globalJackpotTicket: globalJackpotTicketPDA,
        })
        .instruction();

      // 5) Create transaction, add instruction
      transaction.add(instruction);

      // 6) Set recent blockhash, fee payer
      const { blockhash } = await connection.getLatestBlockhash();
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey;

      // 7) Sign transaction
      const signedTx = await wallet.adapter.signTransaction(transaction);

      // 8) Send and confirm
      const txSignature = await connection.sendRawTransaction(
        signedTx.serialize(),
        {
          skipPreflight: false,
          preflightCommitment: "processed",
        }
      );
      console.log("Transaction Signature:", txSignature);

      alert("Tickets purchased successfully!");

      //--------------------------------------
      // 9) Fetch updated ticket account //
      //--------------------------------------
      let retries = 10; // Increased retries
      let delay = 1000; // 1-second initial delay
      let accountInfo;

      for (let attempt = 1; attempt <= retries; attempt++) {
        try {
          accountInfo = await connection.getAccountInfo(ticketPDA);
          if (accountInfo && accountInfo.data) {
            console.log("PDA account loaded successfully.");
            break; // Exit loop on success
          }
          console.log(`Retry ${attempt} failed: No data found.`);
        } catch (error) {
          console.log(`Retry ${attempt} failed: ${error.message}`);
        }
        if (attempt < retries) {
          await new Promise((resolve) => setTimeout(resolve, delay * attempt)); // Exponential backoff
        }
      }

      if (!accountInfo || !accountInfo.data) {
        throw new Error(
          "Ticket PDA does not exist or contains no data after retries."
        );
      }

      // Log raw account data
      console.log("Raw Account Data (Buffer):", accountInfo.data);

      // Log raw account data in Base64 (as seen in Solana Explorer)
      console.log(
        "Raw Account Data (Base64):",
        accountInfo.data.toString("base64")
      );

      // Optionally log as hex (useful for debugging binary structures)
      console.log("Raw Account Data (Hex):", accountInfo.data.toString("hex"));

      // Log data length
      console.log("Data Length:", accountInfo.data.length);

      // Deserialize the account data
      const ticketData = program.coder.accounts.decode(
        "ticket",
        accountInfo.data
      );
      console.log("Decoded Ticket Account:", ticketData);

      // Assuming ticket numbers are stored in `ticketNumbers`
      const newTicketNumbers = ticketData.ticketNumbers.map((ticket) =>
        ticket.toNumber()
      );
      console.log("New Ticket Numbers:", newTicketNumbers);

      //--------------------------------------
      // 10) Send details to your backend
      //--------------------------------------
      const payload = {
        user_id: publicKey.toBase58(),
        competition_id: competition.id,
        competition_PDA: competitionPDA.toBase58(), // Include the competition PDA
        ticket_count: ticketAmount,
        ticket_numbers: newTicketNumbers,
        contribution_amount: programPaymentSOL,
        token_purchase_amount: tokenBuyAmountSOL,
        txSignature,
        blockhash,
        details: {
          protocolFee: protocolFeeSOL.toString(),
          creatorFee: creatorFeeSOL.toString(),
          influencerFee: influencerFeeSOL.toString(),
          globalJackpotFee: globalJackpotFeeSOL.toString(),
          creatorPubkey: creatorPubkey.toBase58(),
          jackpotContribution: jackpotPaymentSOL,
        },
      };

      console.log("Sending transaction details to backend:", payload);
      const response = await axios.post(
        `${API_BASE_URL}/competitions/${competition.id}/entries`,
        payload
      );
      if (response.status === 201) {
        console.log("Backend logging successful:", response.data);
        // Possibly refresh your UI
        fetchCompetition();
        fetchEntries();
      } else {
        alert("Transaction successful, but backend logging failed.");
        console.error("Backend error:", response.data);
      }
    } catch (error) {
      console.error("Error purchasing tickets:", error);
      showError(error.message || "Error buying tickets. Please try again.");
    } finally {
      setIsProcessing(false);
    }
  };

  const handleCancelBuy = () => {
    setShowConfirmation(false); // Close the window
  };

  const ticketPrice = competition.ticket_price;
  const totalCost = (ticketPrice * ticketAmount).toFixed(2);
  const poolPercentage = competition.pool_percentage;
  const tokenBuyPercentage = competition.token_buy_percentage;
  const protocolFeePercentage = competition.total_fees_percentage;

  const userTickets = entries.reduce(
    (acc, entry) =>
      entry.user_id === publicKey?.toBase58() ? acc + entry.ticket_count : acc,
    0
  );
  const uniqueParticipants = new Set(entries.map((entry) => entry.user_id))
    .size; // Count unique user IDs
  const totalTicketsSold = entries.reduce(
    (acc, entry) => acc + entry.ticket_count,
    0
  );

  return (
    <div>
      <CompetitionStyles />
      <div className="competition-item-wrapper">
        <Window className="competition-window">
          <WindowHeader className="window-header-styled">
            <span>
              {competition.token_symbol}_{tokenBuyPercentage}_{poolPercentage}_
              {protocolFeePercentage}.exe
            </span>
            <Button
              onClick={handleClose}
              style={{ marginLeft: "auto", padding: "0 10px" }}
            >
              X
            </Button>
          </WindowHeader>

          <Tabs value={activeTab} onChange={handleChangeTab}>
            <Tab value={0}>Competition</Tab>
            <Tab value={1}>Participants</Tab>
            <Tab value={2}>Chat Room</Tab>
          </Tabs>
          <TabBody>
            {activeTab === 0 && (
              <WindowContent className="window-content-styled">
                <img
                  src={competition.banner_image || "/default-banner.png"}
                  alt={`${competition.token_symbol} Banner`}
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    objectFit: "contain",
                    paddingBottom: "2%",
                  }}
                />
                <div className="token-name-wrapper">
                  <p>{competition.token_name}</p>
                </div>

                {/* Render Logos Here */}
                {LOGOS.some((logo) => competition[logo.linkField]) && (
                  <LogoContainer>
                    {LOGOS.map((logo, index) => {
                      const link = competition[logo.linkField];
                      // Only render the logo if the link is provided
                      if (!link) return null;
                      return (
                        <a
                          key={index}
                          href={formatURL(link)}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()} // Prevents event bubbling
                          aria-label={`Open ${logo.linkField.replace(
                            "_",
                            " "
                          )} link`} // Accessibility
                        >
                          <LogoImage
                            src={logo.src}
                            alt={`${logo.linkField.replace("_", " ")} logo`}
                          />
                        </a>
                      );
                    })}
                  </LogoContainer>
                )}

                <div className="token-text-wrapper">
                  <p>{competition.description}</p>
                </div>
                <div className="competition-content-2">
                  <div className="competition-content">
                    <div className="token-details">
                      <Avatar className="token-avatar">
                        <img
                          src={competition.token_icon}
                          alt={competition.token_symbol}
                        />
                      </Avatar>

                      <p>
                        <div className="contract-address">
                          {truncateAddress(competition.token)}
                          <CopyToClipboard
                            text={competition.token}
                            onCopy={() => setCopied(true)}
                          >
                            <FaCopy className="copy-icon" />
                          </CopyToClipboard>
                        </div>
                      </p>
                    </div>
                    <div className="competition-details-3">
                      <GroupBox
                        className="group-box-styled-ticket-price"
                        label="Ticket Price"
                      >
                        <p>{competition.ticket_price} SOL</p>
                      </GroupBox>
                    </div>
                  </div>

                  <div className="wrapper">
                    <div className="competition-details-2">
                      <GroupBox className="group-box-styled-2" label="Jackpot">
                        <JackpotText
                          isJackpot={progressPercentage >= 100} // Activates glowing effect when 100% or more
                          colors={randomColors} // Applies random gradient colors
                        >
                          {competition && competition.prize_pool
                            ? `${
                                Number(competition.prize_pool) % 1 === 0
                                  ? Number(competition.prize_pool).toFixed(0)
                                  : Number(competition.prize_pool).toFixed(2)
                              } SOL`
                            : "Loading..."}
                        </JackpotText>
                      </GroupBox>
                    </div>
                  </div>
                </div>

                <div className="responsive-frame">
                  <Frame
                    variant="outside"
                    className="countdown-timer"
                    style={{
                      margin: "1rem",
                      padding: ".5rem",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "row",
                      minWidth: "270px",
                    }}
                  >
                    <div className="countdown-timer">
                      TIME LEFT
                      <Frame className="styled-frame">
                        <Counter value={timeString} size={counterSize} />
                      </Frame>
                      <div className="progress-wrapper">
                        PROGRESS
                        <ProgressWrapper>
                          {/* Show "LIVE" or "FINISHED" text with rainbow effect inside the progress bar when progressPercentage is 100 */}
                          <ProgressBar
                            value={progressPercentage}
                            hideValue={progressPercentage === 100} // Hide the percentage when it reaches 100
                          />
                          {progressPercentage === 100 && (
                            <RainbowText colors={randomColors}>
                              {timeString === "00000000" ? "FINISHED" : "LIVE!"}
                            </RainbowText>
                          )}
                        </ProgressWrapper>
                      </div>
                    </div>
                  </Frame>

                  <div className="wrapper-2">
                    <GroupBox
                      className="group-box-styled-responsive"
                      label="Total Tickets"
                    >
                      <div className="number-input-wrapper">
                        <NumberInput
                          value={ticketAmount}
                          step={1}
                          min={1}
                          max={1000}
                          onChange={(value) => handleInputChange(value)}
                          width={130}
                        />
                      </div>
                    </GroupBox>
                    

                    <Tooltip
                        text={
                          remainingTime && remainingTime.days === 0 && remainingTime.hours === 0 && remainingTime.minutes === 0 && remainingTime.seconds === 0
                            ? "Competition has ended"
                            : ""
                        }
                        enterDelay={100}
                        leaveDelay={500}
                      >
                        <Button
                          className="large-button"
                          primary
                          onClick={handleBuyClick}
                          disabled={
                            isProcessing ||
                            (remainingTime &&
                              remainingTime.days === 0 &&
                              remainingTime.hours === 0 &&
                              remainingTime.minutes === 0 &&
                              remainingTime.seconds === 0)
                          }
                        >
                          {remainingTime &&
                          remainingTime.days === 0 &&
                          remainingTime.hours === 0 &&
                          remainingTime.minutes === 0 &&
                          remainingTime.seconds === 0
                            ? "FINISHED"
                            : isProcessing
                            ? "Processing..."
                            : "BUY"}
                        </Button>
                      </Tooltip>


                  </div>
                </div>
              </WindowContent>
            )}

            {activeTab === 1 && (
              <WindowContent className="window-content-styled">
                <div className="group-boxes-container">
                  <GroupBox
                    className="group-box-styled-responsive"
                    label="Your Tickets"
                  >
                    <p>{publicKey ? userTickets : "?"}</p>
                  </GroupBox>
                  <GroupBox
                    className="group-box-styled-responsive"
                    label="Total Participants"
                  >
                    <p>{uniqueParticipants}</p>
                  </GroupBox>
                  <GroupBox
                    className="group-box-styled-responsive"
                    label="Total Tickets"
                  >
                    <p>{totalTicketsSold}</p>
                  </GroupBox>
                </div>

                <Table className="custom-table">
                  <TableHead className="custom-thead">
                    <TableRow className="custom-tr">
                      <TableHeadCell className="custom-th">User</TableHeadCell>
                      <TableHeadCell className="custom-th">Tx ID</TableHeadCell>
                      <TableHeadCell className="custom-th">
                        Tickets
                      </TableHeadCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="custom-tbody">
                    {entries.map((entry, index) => (
                      <TableRow key={index} className="custom-tr">
                        <TableDataCell className="custom-td">
                          <Link to={`/profile/${entry.user_id}`}>
                            {getAddressDisplay(entry.user_id, isSmallScreen)}
                          </Link>
                        </TableDataCell>
                        <TableDataCell className="custom-td"> - </TableDataCell>
                        <TableDataCell className="custom-td">
                          {entry.ticket_count}
                        </TableDataCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </WindowContent>
            )}

            {activeTab === 2 && (
              <WindowContent className="window-content-styled">
                <p>Coming Soon!</p>
              </WindowContent>
            )}
          </TabBody>
        </Window>
      </div>

      {showConfirmation && (
        <div className="PopupWrapper">
          <Window style={{ maxWidth: "400px", width: "95%", minHeight: 200 }}>
            <WindowHeader className="window-header-styled">
              <span>ConfirmPurchase.eXe</span>
              <Button
                onClick={handleCancelBuy}
                style={{ marginLeft: "auto", padding: "0 10px" }}
              >
                X
              </Button>
            </WindowHeader>
            <WindowContent>
              <div className="group-boxes-container2">
                <GroupBox className="group-box-styled" label="Ticket Price">
                  <p>{competition.ticket_price} SOL</p>
                </GroupBox>
                <GroupBox className="group-box-styled" label="Split %">
                  {tokenBuyPercentage}% / {poolPercentage}% /{" "}
                  {protocolFeePercentage}%
                </GroupBox>
              </div>
              <div className="group-boxes-container2">
                <GroupBox className="group-box-styled" label="Buy Tickets">
                  <p>{ticketAmount}</p>
                </GroupBox>

                <GroupBox className="group-box-styled" label="Total Cost">
                  <p>{totalCost} SOL</p>
                </GroupBox>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <Button
                  onClick={handleConfirmBuy}
                  style={{ width: "100px" }}
                  disabled={isProcessing} // Disable if processing
                >
                  {isProcessing ? "Processing..." : "Confirm"}
                </Button>
                <Button onClick={handleCancelBuy} style={{ width: "100px" }}>
                  Cancel
                </Button>
              </div>
            </WindowContent>
          </Window>
        </div>
      )}
    </div>
  );
};

export default CompetitionDetailsPage;
