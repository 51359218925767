import React, { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import axios from "axios";
import {
  Window,
  WindowContent,
  WindowHeader,
  ProgressBar,
  Button,
  Counter,
  GroupBox,
  NumberInput,
} from "react95";
import GlobalJackpotDisplay from "../components/GlobalJackpotDisplay";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import CompetitionStyles from "../styles/CompetitionStyles";
import * as web3 from "@solana/web3.js";
import { useProgram } from "../App";
import BN from "bn.js";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";
const { Buffer } = require("buffer");

// Styled components
const GlobalJackpotPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const JackpotWindow = styled(Window)`
  height: auto;
  width: 60%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  @media (max-width: 1290px) {
    width: 98%;
  }
`;

const WindowHeaderStyled = styled(WindowHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  margin-bottom: 2%;
`;

const WindowContentStyled = styled(WindowContent)`
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const GroupBoxesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
`;

const GroupBoxStyledResponsive = styled(GroupBox)`
  flex: 1;
  min-width: 100px;
  text-align: center;
  p {
    font-size: 2rem;
    font-weight: bold;
  }
`;

// Styled component for the image
const ResponsiveImage = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: contain;
  padding-bottom: 2%;
  margin-bottom: 3%; /* Default margin for larger screens */
`;

// Styled component for the div
const ResponsiveDiv = styled.div`
  width: 100%;

  /* Adjust margin for smaller screens */
  @media (max-width: 768px) {
    margin-top: 10px; /* Increase the margin for smaller screens */
  }

  @media (max-width: 480px) {
    margin-top: 20px; /* Further increase the margin for very small screens */
  }
`;

const ResponsiveDiv2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row; // Changed to row to align elements side by side
  align-items: center; // Vertically aligns the elements
  gap: 10px; // Adds space between GroupBox and Button
  margin-top: 20px;
  justify-content: center;

  @media (max-width: 480px) {
    flex-direction: column; // Changed to row to align elements side by side
  }
`;

const GlobalJackpotPage = () => {
  // State variables
  const [globalJackpot, setGlobalJackpot] = useState(0);
  const [userData, setUserData] = useState(null);
  const [timeString, setTimeString] = useState("");
  const [totalParticipants, setTotalParticipants] = useState(0);
  const [totalTickets, setTotalTickets] = useState(0);
  const { wallet, publicKey } = useWallet();
  const navigate = useNavigate();
  const [counterSize, setCounterSize] = useState("lg");

  const [ticketAmount, setTicketAmount] = useState(1);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [purchaseType, setPurchaseType] = useState("buy"); // 'buy' or 'topOff'

  const { connection } = useConnection();
  const { program } = useProgram();
  const socketRef = useRef(null);

  // Fetch functions
  const fetchGlobalJackpot = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/global-jackpot`);
      setGlobalJackpot(response.data.totalJackpot);
    } catch (error) {
      console.error("Error fetching global jackpot:", error);
    }
  };

  const fetchGlobalJackpotStats = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/global-jackpot/stats`
      );
      setTotalParticipants(response.data.totalParticipants);
      setTotalTickets(response.data.totalTickets);
    } catch (error) {
      console.error("Error fetching global jackpot stats:", error);
    }
  };

  const fetchUserData = async () => {
    if (!publicKey) return;

    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/users/${publicKey.toString()}/global-jackpot`
      );
      setUserData(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const [jackpotConfig, setJackpotConfig] = useState({
    price_per_ticket: "",
    end_time: "",
  });

  // Update the size based on screen width
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setCounterSize("md"); // Use 'md' for smaller screens
      } else {
        setCounterSize("lg"); // Use 'lg' for larger screens
      }
    };

    // Initial check on load
    handleResize();

    // Add event listener to track window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect hooks
  useEffect(() => {
    fetchGlobalJackpot();
  }, []);

  useEffect(() => {
    fetchGlobalJackpotStats();
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [publicKey]);

  useEffect(() => {
    // Initialize Socket.IO connection
    socketRef.current = io(API_BASE_URL);

    // Listen for globalJackpotUpdate event
    socketRef.current.on("globalJackpotUpdate", handleGlobalJackpotUpdate);

    return () => {
      // Cleanup on unmount
      socketRef.current.disconnect();
    };
  }, []);

  const handleGlobalJackpotUpdate = (data) => {
    console.log("Global jackpot updated:", data.totalJackpot);
    setGlobalJackpot(data.totalJackpot);
  };

  // Handlers
  const handleInputChange = (value) => {
    if (!isNaN(value) && value > 0) {
      setTicketAmount(value);
    } else {
      setTicketAmount(1);
    }
  };

  const handleBuyClick = () => {
    setPurchaseType("buy");
    setShowConfirmation(true);
  };

  const handleTopOffClick = () => {
    setPurchaseType("topOff");
    setShowConfirmation(true);
  };

  const handleConfirmBuy = async () => {
    setShowConfirmation(false);

    if (!publicKey || !wallet) {
      alert("Please connect your wallet to proceed.");
      console.error("Wallet not connected.");
      return;
    }

    try {
      console.log("Checking participant Devnet balance...");
      const participantBalanceLamports = await connection.getBalance(publicKey);
      const participantBalanceSOL =
        participantBalanceLamports / web3.LAMPORTS_PER_SOL;
      console.log("Participant Devnet Balance:", participantBalanceSOL, "SOL");

      // Derive PDAs
      console.log("Deriving PDAs...");
      const [globalJackpotPDA] = await web3.PublicKey.findProgramAddress(
        [Buffer.from("global_jackpot")],
        program.programId
      );
      const [globalJackpotTicketPDA] = await web3.PublicKey.findProgramAddress(
        [Buffer.from("global_jackpot_ticket"), publicKey.toBuffer()],
        program.programId
      );
      const [protocolConfigPDA] = await web3.PublicKey.findProgramAddress(
        [Buffer.from("protocol_config")],
        program.programId
      );
      const [protocolFeeAccountPDA] = await web3.PublicKey.findProgramAddress(
        [Buffer.from("protocol_fee_account")],
        program.programId
      );
      const [influencerFeeAccountPDA] = await web3.PublicKey.findProgramAddress(
        [Buffer.from("influencer_fee_account")],
        program.programId
      );

      console.log("PDAs Derived");

      // Fetch protocol and influencer fee percentages
      const configResponse = await axios.get(
        `${API_BASE_URL}/api/public-global-jackpot-config`
      );
      const {
        protocol_fee_percentage,
        influencer_fee_percentage,
        price_per_ticket,
      } = configResponse.data;

      console.log("Fetched Config Percentages and Ticket Price:", {
        protocol_fee_percentage,
        influencer_fee_percentage,
        price_per_ticket,
      });

      // Prepare input values
      const ticketPriceLamports = new BN(
        parseFloat(price_per_ticket) * web3.LAMPORTS_PER_SOL
      );
      const ticketCountBN = new BN(ticketAmount);
      const purchaseAmount = ticketPriceLamports.mul(ticketCountBN);

      const topOffAmountBN = new BN(
        Math.floor(topOffAmount * web3.LAMPORTS_PER_SOL)
      );

      // Calculate fees
      const protocolFee =
        purchaseType === "buy"
          ? purchaseAmount.mul(new BN(protocol_fee_percentage)).div(new BN(100))
          : topOffAmountBN
              .mul(new BN(protocol_fee_percentage))
              .div(new BN(100));

      const influencerFee =
        purchaseType === "buy"
          ? purchaseAmount
              .mul(new BN(influencer_fee_percentage))
              .div(new BN(100))
          : topOffAmountBN
              .mul(new BN(influencer_fee_percentage))
              .div(new BN(100));

      const totalFees = protocolFee.add(influencerFee);
      const totalPayment = topOffAmountBN.add(totalFees);

      console.log("Calculated Payment and Fees:", {
        purchaseAmount: purchaseAmount.toString(),
        topOffAmount: topOffAmountBN.toString(),
        protocolFee: protocolFee.toString(),
        influencerFee: influencerFee.toString(),
        totalFees: totalFees.toString(),
        totalPayment: totalPayment.toString(),
      });

      // Create the transaction
      const transaction = new web3.Transaction();

      if (purchaseType === "buy") {
        // Add the program instruction for buying tickets
        const instruction = await program.methods
          .purchaseGlobalJackpotTickets(ticketCountBN) // Send ticket count
          .accounts({
            globalJackpot: globalJackpotPDA,
            globalJackpotTicket: globalJackpotTicketPDA,
            participant: publicKey,
            protocolFeeAccount: protocolFeeAccountPDA,
            influencerFeeAccount: influencerFeeAccountPDA,
            protocolConfig: protocolConfigPDA,
            systemProgram: web3.SystemProgram.programId,
          })
          .instruction();

        transaction.add(instruction);
      } else if (purchaseType === "topOff") {
        // Add the program instruction for topping off
        const instruction = await program.methods
          .topOffGlobalJackpot() // Send total payment
          .accounts({
            globalJackpot: globalJackpotPDA,
            globalJackpotTicket: globalJackpotTicketPDA,
            participant: publicKey,
            protocolFeeAccount: protocolFeeAccountPDA,
            influencerFeeAccount: influencerFeeAccountPDA,
            protocolConfig: protocolConfigPDA,
            systemProgram: web3.SystemProgram.programId,
          })
          .instruction();

        transaction.add(instruction);
      }

      // Fetch the latest blockhash
      const { blockhash } = await connection.getLatestBlockhash();
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey;

      console.log("Prepared Transaction:", transaction);

      // Sign and send the transaction
      console.log("Signing transaction...");
      const signedTransaction = await wallet.adapter.signTransaction(
        transaction
      );
      console.log("Signed Transaction:", signedTransaction);

      console.log("Sending transaction...");
      const signature = await connection.sendRawTransaction(
        signedTransaction.serialize(),
        { skipPreflight: false, preflightCommitment: "processed" }
      );

      console.log("Transaction Signature:", signature);

      // Log transaction details to the backend
      const payload = {
        user_id: publicKey.toBase58(),
        amount: totalPayment.toString(),
        ticket_count: purchaseType === "buy" ? ticketAmount : undefined,
        topOff: purchaseType === "topOff",
        txSignature: signature,
        blockhash: blockhash,
        details: {
          ticketPriceLamports: ticketPriceLamports.toString(),
          purchaseAmount: purchaseAmount.toString(),
          topOffAmount: topOffAmountBN.toString(),
          protocolFee: protocolFee.toString(),
          influencerFee: influencerFee.toString(),
          totalFees: totalFees.toString(),
          totalPayment: totalPayment.toString(),
        },
      };

      console.log("Sending transaction details to backend:", payload);
      const response = await axios.post(
        `${API_BASE_URL}/api/global-jackpot/purchase`,
        payload
      );

      if (response.data.success) {
        alert(
          purchaseType === "buy"
            ? "Purchase successful!"
            : "Top-off transaction successful!"
        );
        fetchGlobalJackpot();
        fetchGlobalJackpotStats();
        fetchUserData();
      } else {
        console.error("Backend logging failed:", response.data);
        alert("Transaction successful, but backend logging failed.");
      }
    } catch (error) {
      console.error("Error processing transaction:", error);
      alert("Error processing transaction. Please try again.");
    }
  };

  const handleCancelBuy = () => {
    setShowConfirmation(false);
  };

  useEffect(() => {
    const fetchJackpotConfig = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/public-global-jackpot-config`
        );

        console.log("Fetched Public Jackpot Config:", response.data);

        const {
          price_per_ticket,
          end_time,
          protocol_fee_percentage,
          influencer_fee_percentage,
        } = response.data;

        // Convert end_time from seconds to a Date object
        const endTimeValue = parseInt(end_time, 10);
        const date = new Date(endTimeValue);
        console.log("Date object from end_time:", date.toString());

        // Format end time as needed
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");

        const endTimeString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
        console.log("Formatted endTimeString:", endTimeString);

        setJackpotConfig({
          price_per_ticket: price_per_ticket || "",
          end_time: endTimeString,
          protocol_fee_percentage: protocol_fee_percentage || 0,
          influencer_fee_percentage: influencer_fee_percentage || 0,
        });
      } catch (error) {
        console.error("Error fetching jackpot configuration:", error);
      }
    };

    fetchJackpotConfig();
  }, [API_BASE_URL]);

  // Timer logic
  useEffect(() => {
    // If jackpotConfig.end_time is not yet set, don't run the timer code
    if (!jackpotConfig.end_time) return;

    console.log("jackpotconfig:", jackpotConfig.end_time);
    const jackpotEndTime = new Date(jackpotConfig.end_time);

    const updateTimer = () => {
      const now = new Date();
      const distance = jackpotEndTime - now;

      if (distance < 0) {
        setTimeString("00000000");
        clearInterval(timerInterval);
        return;
      }

      const totalDays = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      const daysString = totalDays.toString().padStart(3, "0");
      const hoursString = hours.toString().padStart(2, "0");
      const minutesString = minutes.toString().padStart(2, "0");
      const secondsString = seconds.toString().padStart(2, "0");

      const timeString = `${daysString}${hoursString}${minutesString}${secondsString}`;

      setTimeString(timeString);
    };

    updateTimer();
    const timerInterval = setInterval(updateTimer, 1000);

    return () => clearInterval(timerInterval);
  }, [jackpotConfig.end_time]); // Add jackpotConfig.end_time as a dependency

  const ticketPrice = jackpotConfig.price_per_ticket
    ? parseFloat(jackpotConfig.price_per_ticket)
    : 1; // Fallback if needed

  // Cost calculations
  const protocolFeePercentage = jackpotConfig.protocol_fee_percentage || 0;
  const influencerFeePercentage = jackpotConfig.influencer_fee_percentage || 0;

  // For regular purchase
  const protocolFee = (
    ticketPrice *
    ticketAmount *
    (protocolFeePercentage / 100)
  ).toFixed(2);

  const influencerFee = (
    ticketPrice *
    ticketAmount *
    (influencerFeePercentage / 100)
  ).toFixed(2);

  const totalFees = (
    parseFloat(protocolFee) + parseFloat(influencerFee)
  ).toFixed(2);
  const totalCost = (
    ticketPrice * ticketAmount +
    parseFloat(totalFees)
  ).toFixed(2);

  // For top-off
  const topOffAmount = userData
    ? parseFloat((1 - (userData.totalContribution % 1)).toFixed(2))
    : 0;

  const topOffProtocolFee = (
    topOffAmount *
    (protocolFeePercentage / 100)
  ).toFixed(2);

  const topOffInfluencerFee = (
    topOffAmount *
    (influencerFeePercentage / 100)
  ).toFixed(2);

  const topOffTotalFees = (
    parseFloat(topOffProtocolFee) + parseFloat(topOffInfluencerFee)
  ).toFixed(2);
  const topOffTotalCost = (topOffAmount + parseFloat(topOffTotalFees)).toFixed(
    2
  );

  return (
    <GlobalJackpotPageWrapper>
      <CompetitionStyles />
      <GlobalJackpotDisplay jackpotAmount={globalJackpot} />

      <JackpotWindow>
        <WindowHeaderStyled>
          <span>GlobalJackpotPage.exe</span>
          <Button
            onClick={() => navigate(-1)}
            style={{ marginLeft: "auto", padding: "0 10px" }}
          >
            X
          </Button>
        </WindowHeaderStyled>

        <WindowContentStyled>
          <div style={{ position: "relative" }}>
            <ResponsiveImage
              src="/default-banner.png"
              alt="Global Jackpot Banner"
              style={{
                maxWidth: "100%",
                height: "auto",
                objectFit: "contain",
                paddingBottom: "2%",
              }}
              onError={(e) => {
                e.target.onerror = null; // Prevents infinite loop if default image also fails
                e.target.src = "/default-banner.png";
              }}
            />

            <div
              style={{
                position: "absolute",
                top: "70%", // adjust this value to move the div above the img
                left: "50%",
                transform: "translateX(-50%)", // centers the div horizontally
                marginBottom: "10px",
                textAlign: "center",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Counter
                  value={timeString}
                  size={counterSize}
                  style={{ textAlign: "left" }}
                />
              </div>
            </div>
          </div>
          <ResponsiveDiv>
            <GroupBoxesContainer>
              <GroupBoxStyledResponsive label="Your Tickets">
                <p>{userData ? Math.floor(userData.tickets) : "?"}</p>
              </GroupBoxStyledResponsive>
              <GroupBoxStyledResponsive label="Total Participants">
                <p>{totalParticipants}</p>
              </GroupBoxStyledResponsive>
              <GroupBoxStyledResponsive label="Total Tickets">
                <p>{totalTickets}</p>
              </GroupBoxStyledResponsive>
            </GroupBoxesContainer>
          </ResponsiveDiv>
          {/* New Buttons and Input */}
          <ResponsiveDiv2>
            <GroupBox className="group-box-styled-2" label="Buy Tickets">
              <NumberInput
                value={ticketAmount}
                step={1}
                min={1}
                max={1000}
                onChange={(value) => handleInputChange(value)}
                width={130}
              />
            </GroupBox>

            <Button
              className="large-button"
              primary
              onClick={handleBuyClick}
              style={{ marginTop: "5px" }} // Add margin-top to the button
            >
              Buy
            </Button>
          </ResponsiveDiv2>

          <p style={{ marginTop: "20px", fontWeight: "bold" }}>
            Progress towards next ticket:
          </p>
          <ProgressBar
            value={
              userData ? ((userData.totalContribution % 1) * 100).toFixed(2) : 0
            }
          />
          <Button fullWidth onClick={handleTopOffClick}>
            Top Off
          </Button>
        </WindowContentStyled>
      </JackpotWindow>

      {/* Confirmation Modal */}
      {showConfirmation && (
        <div className="PopupWrapper">
          <Window
            className="window"
            style={{ maxWidth: "400px", width: "95%", minHeight: 200 }}
          >
            <WindowHeader className="window-header-styled">
              <span>ConfirmPurchase.exe</span>
              <Button
                onClick={handleCancelBuy}
                style={{ marginLeft: "auto", padding: "0 10px" }}
              >
                X
              </Button>
            </WindowHeader>
            <WindowContent>
              <div className="group-boxes-container3">
                {purchaseType === "buy" && (
                  <>
                    <GroupBox
                      className="group-box-styled"
                      label="Total Tickets"
                    >
                      <p>{ticketAmount}</p>
                    </GroupBox>
                    <GroupBox className="group-box-styled" label="Total Fees">
                      <p>{totalFees} SOL</p>
                    </GroupBox>
                    <GroupBox className="group-box-styled" label="Total Cost">
                      <p>{totalCost} SOL</p>
                    </GroupBox>
                  </>
                )}
              </div>
              <div className="group-boxes-container3">
                {purchaseType === "topOff" && (
                  <>
                    <GroupBox className="group-box-styled" label="Top Off">
                      <p>{topOffAmount} SOL</p>
                    </GroupBox>
                    <GroupBox className="group-box-styled" label="Total Fees">
                      <p>{topOffTotalFees} SOL</p>
                    </GroupBox>
                    <GroupBox className="group-box-styled" label="Total Cost">
                      <p>{topOffTotalCost} SOL</p>
                    </GroupBox>
                  </>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                  marginTop: "20px",
                }}
              >
                <Button onClick={handleConfirmBuy} style={{ width: "100px" }}>
                  Confirm
                </Button>
              </div>
            </WindowContent>
          </Window>
        </div>
      )}
    </GlobalJackpotPageWrapper>
  );
};

export default GlobalJackpotPage;
