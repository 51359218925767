import React, { useState, useEffect } from "react";
import {
  Button,
  TextInput,
  Checkbox,
  NumberInput,
  Window,
  WindowContent,
  WindowHeader,
  Hourglass,
  Slider,
} from "react95";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import * as web3 from "@solana/web3.js";
import { useProgram } from "../App";
import BN from "bn.js";
import { useError } from "../context/ErrorContext";
import validator from "validator";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";
const { Buffer } = require("buffer");

// Style for the form
const CompetitionForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
`;

// Wrapper for the modal content
const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
`;

const ResponsiveWindow = styled(Window)`
  width: 100%;
  max-width: 600px;
  pointer-events: auto;
  overflow-y: auto;
  padding: 10px;
  box-sizing: border-box;
`;

const TokenInfo = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    width: 32px;
    height: 32px;
  }
`;

const InputWithButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SearchButton = styled(Button)`
  width: 90px;
`;

const SliderWrapper = styled.div`
  margin-top: 15px;
  padding: 10px;
  border-radius: 5px;
`;

const Label = styled.label`
  font-weight: bold;
`;

const SliderDetails = styled.div`
  margin-top: 10px;
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
`;

// Validate Telegram URL
const isValidTelegram = (url) => {
  // Allow URLs with or without protocol
  const telegramRegex =
    /^(https?:\/\/)?(t\.me|telegram\.me)\/[A-Za-z0-9_]{5,32}$/;
  return telegramRegex.test(url);
};

// Validate Discord URL
const isValidDiscord = (url) => {
  // Allow URLs with or without protocol
  const discordRegex = /^(https?:\/\/)?(www\.)?discord\.gg\/[A-Za-z0-9]+$/;
  return discordRegex.test(url);
};

// Validate X (Twitter) URL
const isValidX = (url) => {
  // Allow URLs with or without protocol
  const xRegex =
    /^(https?:\/\/)?(www\.)?(twitter\.com|x\.com)\/[A-Za-z0-9_]{1,15}$/;
  return xRegex.test(url);
};

// Validate general URL (for website) without requiring protocol
const isValidURL = (url) => {
  return validator.isURL(url, {
    require_protocol: false, // Protocol is optional
    require_valid_protocol: false, // Do not require a valid protocol
    protocols: ["http", "https"], // Allow only http and https protocols if present
  });
};

// Helper function to validate Solana public keys
const isValidSolanaAddress = (address) => {
  try {
    new web3.PublicKey(address);
    return true;
  } catch (error) {
    return false;
  }
};

// Helper function to prepend https:// if missing
const formatURL = (url) => {
  if (!url) return ""; // Return empty string if no URL provided
  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`;
  }
  return url;
};

const TextInputStyled = styled(TextInput)`
  border: ${(props) => (props.isInvalid ? "2px solid red" : "1px solid #ccc")};
`;

// Styled component for inline error messages
const ErrorMessage = styled.p`
  color: red;
  font-size: 0.85rem;
  margin-top: -8px;
  margin-bottom: 8px;
`;

const CompetitionPage = () => {
  const [formData, setFormData] = useState({
    contractAddress: "",
    ticketPrice: 0,
    seedAmount: 0,
    seedEnabled: false,
    endDate: "",
    endTime: "",
    customAllocationEnabled: false,
    poolPercentage: 20,
    description: "",
    telegramLink: "",
    discordLink: "",
    websiteLink: "",
    xLink: "",
  });
  const { showError, clearError } = useError();
  const [selectedToken, setSelectedToken] = useState(null);
  const [feePercentages, setFeePercentages] = useState({
    totalFeesPercentage: 5, // Default value
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { wallet, publicKey } = useWallet();
  const { connection } = useConnection();
  const { program } = useProgram();
  const [isWalletErrorDismissed, setIsWalletErrorDismissed] = useState(false);

  useEffect(() => {
    if (!publicKey && !isWalletErrorDismissed) {
      showError("Please connect your wallet first.");
    } else if (publicKey && !isWalletErrorDismissed) {
      clearError();
      setIsWalletErrorDismissed(true); // stops repeated clearing
    }
  }, [publicKey, isWalletErrorDismissed, showError, clearError]);

  // Fetch fee percentages from backend
  useEffect(() => {
    const fetchProtocolConfig = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/protocol-config`);
        const feeConfigMap = response.data.reduce((acc, item) => {
          acc[item.key] = parseFloat(item.value);
          return acc;
        }, {});
        const protocol_fee_percentage = feeConfigMap.PROTOCOL_FEE_PERCENTAGE || 0;
        const creator_fee_percentage = feeConfigMap.CREATOR_FEE_PERCENTAGE || 0;
        const global_jackpot_fee_percentage =
          feeConfigMap.GLOBAL_JACKPOT_FEE_PERCENTAGE || 0;
        const influencer_fee_percentage =
          feeConfigMap.INFLUENCER_FEE_PERCENTAGE || 0;
        const totalFeesPercentage =
          protocol_fee_percentage +
          creator_fee_percentage +
          global_jackpot_fee_percentage +
          influencer_fee_percentage;
        setFeePercentages({
          protocol_fee_percentage,
          creator_fee_percentage,
          global_jackpot_fee_percentage,
          influencer_fee_percentage,
          totalFeesPercentage,
        });
      } catch (error) {
        console.error("Error fetching protocol configuration:", error);
        setErrors((prev) => ({
          ...prev,
          protocol: "Failed to fetch protocol configuration.",
        }));
      }
    };
    fetchProtocolConfig();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({ ...formData, [name]: newValue });

    let errorMsg = "";
    switch (name) {
      case "telegramLink":
        if (value && !isValidTelegram(value)) {
          errorMsg = "Please enter a valid Telegram link.";
        }
        break;
      case "discordLink":
        if (value && !isValidDiscord(value)) {
          errorMsg = "Please enter a valid Discord link.";
        }
        break;
      case "xLink":
        if (value && !isValidX(value)) {
          errorMsg = "Please enter a valid X (Twitter) link.";
        }
        break;
      case "websiteLink":
        if (value && !isValidURL(value)) {
          errorMsg = "Please enter a valid Website link.";
        }
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: errorMsg });
  };

  const fetchTokenInfo = async () => {
    setIsLoading(true);
    try {
      if (!formData.contractAddress) {
        showError("Please enter a valid contract address.");
        setIsLoading(false);
        return;
      }
      const response = await axios.get(`${API_BASE_URL}/api/token-info`, {
        params: { address: formData.contractAddress },
      });
      if (response.data) {
        setSelectedToken(response.data.data);
      } else {
        showError("Token not found. Please check the contract address.");
      }
    } catch (error) {
      console.error("Error fetching token metadata:", error.message);
      showError(
        error.response?.data?.errors?.message ||
          "Failed to fetch token information. Please check the contract address."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const resetTokenInfo = () => {
    setFormData({ ...formData, contractAddress: "" });
    setSelectedToken(null);
    clearError();
  };

  const handleNumberChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSliderChange = (newValue) => {
    setFormData({ ...formData, poolPercentage: newValue });
  };

  // Helper to check form validity for enabling the submit button
  const isFormValid = () => {
    const {
      contractAddress,
      ticketPrice,
      endDate,
      endTime,
      description,
      seedEnabled,
      seedAmount,
      customAllocationEnabled,
      poolPercentage,
    } = formData;

    if (
      !contractAddress ||
      !ticketPrice ||
      ticketPrice <= 0 ||
      !endDate ||
      !endTime ||
      !description.trim()
    ) {
      return false;
    }
    if (seedEnabled && (!seedAmount || seedAmount <= 0)) {
      return false;
    }
    if (
      customAllocationEnabled &&
      (!poolPercentage || poolPercentage < 5 || poolPercentage > 90)
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Collect errors in an array
    const errorsArr = [];
    const { telegramLink, discordLink, websiteLink, xLink, contractAddress } = formData;

    // 1) Basic field checks
    if (!contractAddress) {
      errorsArr.push("Contract address is required.");
    } else if (!isValidSolanaAddress(contractAddress)) {
      errorsArr.push("Please enter a valid Solana contract address.");
    }
    if (!selectedToken) {
      errorsArr.push("Please search and validate the token address.");
    }
    if (!formData.ticketPrice || formData.ticketPrice <= 0) {
      errorsArr.push("Ticket price must be greater than zero.");
    }
    if (!formData.endDate) {
      errorsArr.push("End date is required.");
    }
    if (!formData.endTime) {
      errorsArr.push("End time is required.");
    }
    if (!formData.description.trim()) {
      errorsArr.push("Description is required.");
    }

    // 2) Conditional checks
    if (formData.seedEnabled) {
      if (!formData.seedAmount || formData.seedAmount <= 0) {
        errorsArr.push("Seed amount must be greater than zero.");
      }
    }
    if (formData.customAllocationEnabled) {
      if (
        !formData.poolPercentage ||
        formData.poolPercentage < 5 ||
        formData.poolPercentage > 90
      ) {
        errorsArr.push("Pool percentage must be between 5 and 90.");
      }
    }

    // 3) Optional Link checks
    if (telegramLink && !isValidTelegram(telegramLink)) {
      errorsArr.push("Please enter a valid Telegram link.");
    }
    if (discordLink && !isValidDiscord(discordLink)) {
      errorsArr.push("Please enter a valid Discord link.");
    }
    if (xLink && !isValidX(xLink)) {
      errorsArr.push("Please enter a valid X (Twitter) link.");
    }
    if (websiteLink && !isValidURL(websiteLink)) {
      errorsArr.push("Please enter a valid Website link.");
    }

    // Show them all at once if there's any error
    if (errorsArr.length > 0) {
      showError(errorsArr.join("\n"));
      return;
    }

    // Proceed if no errors
    try {
      if (!publicKey || !wallet) {
        throw new Error("Wallet not connected.");
      }
      if (!(publicKey instanceof web3.PublicKey)) {
        throw new Error("Invalid public key.");
      }
      // Derive the PDA for the creator
      const [creatorPDA] = await web3.PublicKey.findProgramAddress(
        [Buffer.from("creator"), publicKey.toBuffer()],
        program.programId
      );

      console.log("Creator PDA:", creatorPDA.toBase58());

      // Check if creatorAccount exists
      const creatorAccount = await connection.getAccountInfo(creatorPDA);
      if (!creatorAccount) {
        console.log("Initializing creator account...");

        // Initialize the creator account
        const instruction = await program.methods
          .initializeCreator()
          .accounts({
            creator: creatorPDA,
            authority: publicKey,
            systemProgram: web3.SystemProgram.programId,
          })
          .instruction();

        const transaction = new web3.Transaction().add(instruction);

        // Fetch latest blockhash
        const { blockhash } = await connection.getLatestBlockhash();
        transaction.recentBlockhash = blockhash;
        transaction.feePayer = publicKey;

        // Sign and send transaction
        const signedTransaction = await wallet.adapter.signTransaction(
          transaction
        );
        const signature = await connection.sendRawTransaction(
          signedTransaction.serialize(),
          { skipPreflight: false, preflightCommitment: "processed" }
        );

        console.log("Creator account initialized:", signature);
      } else {
        console.log("Creator account already exists.");
      }

      // Fetch creator account data
      console.log("Fetching creator account data...");
      const creatorAccountInfo = await connection.getAccountInfo(creatorPDA);
      if (!creatorAccountInfo) {
        throw new Error("Failed to fetch creator account data.");
      }

      const dataView = new DataView(creatorAccountInfo.data.buffer);

      // competition_count starts at byte offset 72
      const competitionCount = Number(dataView.getBigUint64(72, true)); // little-endian
      console.log("Competition count:", competitionCount);

      // Create the competitionCountBuffer
      const competitionCountBuffer = Buffer.alloc(8);
      competitionCountBuffer.writeBigUInt64LE(BigInt(competitionCount));

      // Derive the PDA for the competition
      const [competitionPDA] = await web3.PublicKey.findProgramAddress(
        [
          Buffer.from("competition"),
          creatorPDA.toBuffer(),
          competitionCountBuffer,
        ],
        program.programId
      );

      console.log("Competition PDA:", competitionPDA.toBase58());

      // Derive the PDA for the protocol_config (assuming it has been initialized already)
      const [protocolConfigPDA] = await web3.PublicKey.findProgramAddress(
        [Buffer.from("protocol_config")],
        program.programId
      );

      // Parse form data and convert values to BN
      const endTimestamp = new BN(
        Math.floor(
          new Date(`${formData.endDate}T${formData.endTime}`).getTime() / 1000
        )
      );
      const formattedTicketPrice = new BN(
        Math.floor(formData.ticketPrice * web3.LAMPORTS_PER_SOL)
      ); // Convert to lamports
      const formattedSeedAmount = new BN(
        formData.seedEnabled
          ? Math.floor(formData.seedAmount * web3.LAMPORTS_PER_SOL)
          : 0
      );

      const poolPercentage = formData.customAllocationEnabled
        ? formData.poolPercentage
        : 20;

      const tokenBuyPercentage =
        100 - poolPercentage - feePercentages.totalFeesPercentage;

      // Ensure tokenBuyPercentage is a valid number
      if (isNaN(tokenBuyPercentage)) {
        throw new Error("Invalid tokenBuyPercentage calculation");
      }

      console.log("Token Buy Percentage:", tokenBuyPercentage); // Debugging line

      const poolPercentageBN = new BN(poolPercentage);

      console.log("Competition creation parameters:", {
        description: formData.description,
        ticketPrice: formattedTicketPrice.toString(),
        endTime: endTimestamp.toString(),
        tokenBuyPercentage: tokenBuyPercentage.toString(),
        poolPercentage: poolPercentageBN.toString(),
        tokenAddress: formData.contractAddress,
        seedAmount: formattedSeedAmount.toString(),
      });

      const tokenBuyPercentageBPS = tokenBuyPercentage * 100;
      const poolPercentageBPS = poolPercentage * 100;

      // Create PublicKey for token address
      const tokenPubkey = new web3.PublicKey(formData.contractAddress);
      console.log("Token PublicKey:", tokenPubkey.toBase58());

      // Generate the instruction for creating the competition
      const instruction = await program.methods
        .createCompetition(
          formData.description,
          formattedTicketPrice,
          endTimestamp,
          tokenBuyPercentageBPS,
          poolPercentageBPS,
          tokenPubkey,
          formattedSeedAmount
        )
        .accounts({
          creator: creatorPDA,
          authority: publicKey,
          competition: competitionPDA,
          protocolConfig: protocolConfigPDA,
          systemProgram: web3.SystemProgram.programId,
        })
        .instruction();

      // Create a transaction and add the instruction
      const transaction = new web3.Transaction().add(instruction);

      // Fetch the latest blockhash
      const { blockhash } = await connection.getLatestBlockhash();
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey;

      console.log(
        "Transaction prepared for competition creation:",
        transaction
      );

      // Attempt to sign and send the transaction
      if (wallet.adapter.signTransaction) {
        console.log("Using wallet adapter for signing competition creation...");
        const signedTransaction = await wallet.adapter.signTransaction(
          transaction
        );

        console.log(
          "Signed transaction for competition creation:",
          signedTransaction
        );

        const txSignature = await connection.sendRawTransaction(
          signedTransaction.serialize(),
          {
            skipPreflight: false,
            preflightCommitment: "processed",
          }
        );

        console.log("Competition created with signature:", txSignature);

        // Construct the Solana link automatically
        const solanaLink = `https://solscan.io/token/${formData.contractAddress}`;

        // Format URLs to ensure they have protocols
        const formattedTelegramLink = formatURL(formData.telegramLink);
        const formattedDiscordLink = formatURL(formData.discordLink);
        const formattedWebsiteLink = formatURL(formData.websiteLink);
        const formattedXLink = formatURL(formData.xLink);

        console.log("Payload being sent to backend:", {
          creator_id: publicKey.toBase58(),
          ticket_price: formData.ticketPrice,
          duration: Math.floor(endTimestamp.toNumber() - Date.now() / 1000),
          end_time: new Date(endTimestamp.toNumber() * 1000).toISOString(),
          token: formData.contractAddress,
          poolPercentage, // Log this explicitly
          seed_amount: formData.seedEnabled ? formData.seedAmount : 0,
          description: formData.description,
          txSignature, // Include transaction signature for verification
          pda_address: competitionPDA.toBase58(), // Include the PDA address
          token_buy_percentage: tokenBuyPercentage, // Log this explicitly
          token_name: selectedToken?.name || "", // Include token name
          token_symbol: selectedToken?.symbol || "", // Include token symbol
          token_icon: selectedToken?.icon || "", // Include token icon
          telegram_link: formattedTelegramLink || null,
          discord_link: formattedDiscordLink || null,
          solana_link: solanaLink, // Include the automatically generated Solana link
          website_link: formattedWebsiteLink || null,
          x_link: formattedXLink || null,
        });

        // Add backend API call here
        try {
          const response = await axios.post(`${API_BASE_URL}/competitions`, {
            creator_id: publicKey.toBase58(),
            ticket_price: formData.ticketPrice,
            duration: Math.floor(endTimestamp.toNumber() - Date.now() / 1000),
            end_time: new Date(endTimestamp.toNumber() * 1000).toISOString(),
            token: formData.contractAddress,
            pool_percentage: poolPercentage,
            seed_amount: formData.seedEnabled ? formData.seedAmount : 0,
            description: formData.description,
            txSignature, // Include transaction signature for verification
            pda_address: competitionPDA.toBase58(), // Include the PDA address
            token_buy_percentage: tokenBuyPercentage,
            token_name: selectedToken?.name || "", // Include token name
            token_symbol: selectedToken?.symbol || "", // Include token symbol
            token_icon: selectedToken?.icon || "", // Include token icon
            telegram_link: formattedTelegramLink || null,
            discord_link: formattedDiscordLink || null,
            solana_link: solanaLink, // Include the automatically generated Solana link
            website_link: formattedWebsiteLink || null,
            x_link: formattedXLink || null,
          });

          alert(`Competition created successfully! Signature: ${txSignature}`);
          navigate(`/competition/${response.data.competition.id}`);

          // Optional: Reset form after successful submission
          setFormData({
            contractAddress: "",
            ticketPrice: 0,
            seedAmount: 0,
            seedEnabled: false,
            endDate: "",
            endTime: "",
            customAllocationEnabled: false,
            poolPercentage: 20,
            description: "",
            telegramLink: "",
            discordLink: "",
            websiteLink: "",
            xLink: "",
          });
          setSelectedToken(null);
          setErrors({});
        } catch (apiError) {
          console.error("Error sending data to the backend:", apiError);
          showError("Failed to save competition to the backend. Please try again.");

        }
      } else {
        throw new Error("Wallet does not support signing transactions.");
      }
    } catch (error) {
      console.error("Error during competition creation:", error);
      showError(error.message || "Failed to create competition. Please try again.");
    }
    
  };

  const handleBackNavigation = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <Wrapper>
      <ResponsiveWindow>
        <WindowHeader
          className="window-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>CreateCompetition.eXe</span>
          <Button
            onClick={handleBackNavigation}
            style={{ marginLeft: "auto", padding: "0 10px" }}
          >
            X
          </Button>
        </WindowHeader>
        <WindowContent>
          <CompetitionForm onSubmit={handleSubmit}>
            <label>Token Address *</label>
            <InputWithButton>
              <TextInput
                placeholder="Enter Contract Address"
                name="contractAddress"
                value={formData.contractAddress}
                onChange={handleChange}
                fullWidth
              />
              <SearchButton
                type="button"
                onClick={selectedToken ? resetTokenInfo : fetchTokenInfo}
                disabled={isLoading || !formData.contractAddress}
              >
                {selectedToken ? (
                  "Reset"
                ) : isLoading ? (
                  <Hourglass size={16} />
                ) : (
                  "Search"
                )}
              </SearchButton>
            </InputWithButton>
            {errors.fetchToken && (
              <ErrorMessage>{errors.fetchToken}</ErrorMessage>
            )}
            {errors.wallet && <ErrorMessage>{errors.wallet}</ErrorMessage>}
            {selectedToken && (
              <TokenInfo>
                <img src={selectedToken.icon} alt={selectedToken.symbol} />
                <div>
                  <strong>{selectedToken.name}</strong>
                  <p>${selectedToken.symbol}</p>
                </div>
              </TokenInfo>
            )}
            {/* Description Input */}
            <label>Description *</label>
            <TextInput
              name="description"
              value={formData.description}
              onChange={handleChange}
              multiline
              rows={4}
              maxLength={250}
              fullWidth
            />
            {errors.description && (
              <ErrorMessage>{errors.description}</ErrorMessage>
            )}

            {/* Ticket Price */}
            <label>Ticket Price *</label>
            <NumberInput
              defaultValue={formData.ticketPrice}
              step={0.1}
              min={0.1}
              onChange={(value) => {
                if (value >= 0.1) {
                  handleNumberChange("ticketPrice", value);
                }
              }}
              width="100%"
            />
            {errors.ticketPrice && (
              <ErrorMessage>{errors.ticketPrice}</ErrorMessage>
            )}

            {/* End Date and Time */}
            <label>End Date *</label>
            <TextInput
              type="date"
              name="endDate"
              value={formData.endDate}
              onChange={handleChange}
              fullWidth
            />
            {errors.endDate && <ErrorMessage>{errors.endDate}</ErrorMessage>}
            <label>End Time *</label>
            <TextInput
              type="time"
              name="endTime"
              value={formData.endTime}
              onChange={handleChange}
              fullWidth
            />
            {errors.endTime && <ErrorMessage>{errors.endTime}</ErrorMessage>}

            {/* Website Link */}
            <label>Website Link</label>
            <TextInput
              as={TextInputStyled}
              isInvalid={!!errors.websiteLink}
              name="websiteLink"
              value={formData.websiteLink}
              onChange={handleChange}
              placeholder="e.g., google.com"
              fullWidth
            />
            {errors.websiteLink && (
              <ErrorMessage>{errors.websiteLink}</ErrorMessage>
            )}

            {/* Telegram Link */}
            <label>Telegram Link</label>
            <TextInput
              as={TextInputStyled}
              isInvalid={!!errors.telegramLink}
              name="telegramLink"
              value={formData.telegramLink}
              onChange={handleChange}
              placeholder="e.g., t.me/your_telegram"
              fullWidth
            />
            {errors.telegramLink && (
              <ErrorMessage>{errors.telegramLink}</ErrorMessage>
            )}

            {/* Discord Link */}
            <label>Discord Link</label>
            <TextInput
              as={TextInputStyled}
              isInvalid={!!errors.discordLink}
              name="discordLink"
              value={formData.discordLink}
              onChange={handleChange}
              placeholder="e.g., discord.gg/your_discord"
              fullWidth
            />
            {errors.discordLink && (
              <ErrorMessage>{errors.discordLink}</ErrorMessage>
            )}

            {/* X (Twitter) Link */}
            <label>X (Twitter) Link</label>
            <TextInput
              as={TextInputStyled}
              isInvalid={!!errors.xLink}
              name="xLink"
              value={formData.xLink}
              onChange={handleChange}
              placeholder="e.g., twitter.com/your_handle"
              fullWidth
            />
            {errors.xLink && <ErrorMessage>{errors.xLink}</ErrorMessage>}

            {/* Seed Competition */}
            <Checkbox
              label="Seed the competition?"
              name="seedEnabled"
              checked={formData.seedEnabled}
              onChange={handleChange}
            />
            {formData.seedEnabled && (
              <>
                <label>Seed Amount *</label>
                <NumberInput
                  defaultValue={formData.seedAmount}
                  onChange={(value) => handleNumberChange("seedAmount", value)}
                  width="100%"
                />
                {errors.seedAmount && (
                  <ErrorMessage>{errors.seedAmount}</ErrorMessage>
                )}
              </>
            )}

            {/* Custom Allocation */}
            <Checkbox
              label="Customize Allocation?"
              name="customAllocationEnabled"
              checked={formData.customAllocationEnabled}
              onChange={handleChange}
            />
            {formData.customAllocationEnabled && (
              <SliderWrapper>
                <Label>Allocate Ticket Price:</Label>
                <Slider
                  size="100%"
                  min={5}
                  max={90}
                  value={formData.poolPercentage}
                  onChange={handleSliderChange}
                />
                <SliderDetails>
                  <span>
                    Buy Tokens:{" "}
                    {100 -
                      formData.poolPercentage -
                      feePercentages.totalFeesPercentage}
                    %
                  </span>
                  <span>Jackpot/Pool: {formData.poolPercentage}%</span>
                  <span>Protocol: {feePercentages.totalFeesPercentage}%</span>
                </SliderDetails>
                {errors.poolPercentage && (
                  <ErrorMessage>{errors.poolPercentage}</ErrorMessage>
                )}
              </SliderWrapper>
            )}
            {errors.backend && <ErrorMessage>{errors.backend}</ErrorMessage>}
            {errors.submission && (
              <ErrorMessage>{errors.submission}</ErrorMessage>
            )}
            <Button type="submit" disabled={isLoading || !isFormValid()}>
              {isLoading ? "Creating..." : "Create Competition"}
            </Button>
          </CompetitionForm>
        </WindowContent>
      </ResponsiveWindow>
    </Wrapper>
  );
};

export default CompetitionPage;
