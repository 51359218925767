// DashboardPage.jsx

import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import {
  Window,
  WindowContent,
  WindowHeader,
  Button,
  GroupBox,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableDataCell,
  TableHeadCell,
  TextInput,
  Tabs,
  Tab,
  TabBody,
  MenuListItem,
} from "react95";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useWallet } from "@solana/wallet-adapter-react";
import { Connection, PublicKey, SystemProgram } from "@solana/web3.js";
import { Program, AnchorProvider, BN } from "@coral-xyz/anchor";
import { AdminContext } from "../context/AdminContext";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";

// Styled components for admin page
const AdminPageWrapper = styled.div`
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AdminWindow = styled(Window)`
  width: 90%;
  max-width: 1200px;
  margin: 20px 0;
`;

const WindowHeaderStyled = styled(WindowHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
`;

const CenteredTextInput = styled(TextInput)`
  margin-bottom: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
`;

// Scrollable table container with fixed table headers and hidden scrollbar
const ScrollableTable = styled.div`
  max-height: 600px;
  overflow-y: auto;
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const SectionSeparator = styled.hr`
  margin: 20px 0;
  border: none;
  border-top: 2px solid #ccc;
`;

const StyledTable = styled(Table)`
  width: 100%;
  border-collapse: collapse;
  overflow: auto;

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  th,
  td {
    padding: 8px 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
`;

const CenteredButton = styled(Button)`
  display: block;
  margin: 10px auto; /* Centers the button */
`;

const FormRow = styled.div`
  display: flex;
  align-items: center; /* Align input and label vertically */
  gap: 10px; /* Space between label and input */
  margin-bottom: 15px;

  label {
    flex: 0 0 25%; /* Label takes up 20% of the row's width */
    text-align: left; /* Align label text to the right */
    padding-right: 10px; /* Add some padding for better spacing */
    font-weight: bold; /* Optional: Make label text bold */
  }

  input {
    flex: 1; /* Input takes the remaining space */
  }
`;

// Row for side-by-side buttons
const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 10px 0;
`;

// Row to center influencer and remove button
const CenteredRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
`;

const DashboardPage = () => {
  const [competitions, setCompetitions] = useState([]);
  const [users, setUsers] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [userJackpotData, setUserJackpotData] = useState({});
  const [entries, setEntries] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [influencerFeeAccountBalance, setInfluencerFeeAccountBalance] =
    useState(0);

  const [searchCompetitions, setSearchCompetitions] = useState("");
  const [searchUsers, setSearchUsers] = useState("");
  const [searchTransactions, setSearchTransactions] = useState("");

  const [sortConfig, setSortConfig] = useState({
    key: "id",
    ascending: false,
  });

  const navigate = useNavigate();

  // State variables for fee configurations
  const [feeConfig, setFeeConfig] = useState({
    PROTOCOL_FEE_PERCENTAGE: "",
    CREATOR_FEE_PERCENTAGE: "",
    GLOBAL_JACKPOT_FEE_PERCENTAGE: "",
    INFLUENCER_FEE_PERCENTAGE: "",
    MIN_JACKPOT_MULTIPLE: "",
  });

  // State variables for influencer management
  const [influencerList, setInfluencerList] = useState([]);
  const [newInfluencerPublicKey, setNewInfluencerPublicKey] = useState("");
  const [influencerMessage, setInfluencerMessage] = useState("");

  // State variables for global jackpot management
  const [jackpotConfig, setJackpotConfig] = useState({
    price_per_ticket: "",
    end_time: "",
  });
  const [jackpotMessage, setJackpotMessage] = useState("");

  // State variable for config messages
  const [configMessage, setConfigMessage] = useState("");

  // Use wallet adapter to get the connected wallet address and sign transactions
  const { publicKey } = useWallet();
  const [adminWalletAddress, setAdminWalletAddress] = useState("");

  // Connection to the Solana cluster
  const connection = new Connection(process.env.REACT_APP_SOLANA_RPC_URL);

  // Import isAdmin from AdminContext
  const { isAdmin } = useContext(AdminContext);

  // Fetch admin wallet address when wallet connects
  useEffect(() => {
    if (publicKey) {
      console.log("Connected wallet address:", publicKey.toBase58());
      setAdminWalletAddress(publicKey.toBase58());
    }
  }, [publicKey]);

  useEffect(() => {
    const fetchInfluencerFeeAccountBalance = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/influencer-fee-account-balance`
        );
        setInfluencerFeeAccountBalance(response.data.balance);
      } catch (error) {
        console.error("Error fetching influencer fee account balance:", error);
      }
    };

    fetchInfluencerFeeAccountBalance();
  }, []);

  // Fetch fee and jackpot configurations
  useEffect(() => {
    const fetchConfig = async () => {
      if (!adminWalletAddress) return;

      try {
        const response = await axios.get(`${API_BASE_URL}/admin/config`, {
          params: {
            user_id: adminWalletAddress,
          },
        });
        const configs = response.data;

        const configMap = {};
        configs.forEach((config) => {
          configMap[config.key] = config.value;
        });

        // Update fee configs
        setFeeConfig((prevConfig) => ({
          ...prevConfig,
          ...configMap,
        }));

        // Convert GLOBAL_JACKPOT_END_TIME from Unix timestamp (in seconds) to "datetime-local" format
        let endTimeString = "";
        if (configMap["GLOBAL_JACKPOT_END_TIME"]) {
          const endTimeValue = parseInt(
            configMap["GLOBAL_JACKPOT_END_TIME"],
            10
          );
          const date = new Date(endTimeValue);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          const hours = String(date.getHours()).padStart(2, "0");
          const minutes = String(date.getMinutes()).padStart(2, "0");
          endTimeString = `${year}-${month}-${day}T${hours}:${minutes}`;
        }

        // Update jackpot configuration
        setJackpotConfig((prevConfig) => ({
          ...prevConfig,
          price_per_ticket: configMap["GLOBAL_JACKPOT_PRICE_PER_TICKET"] || "",
          end_time: endTimeString,
        }));
      } catch (error) {
        console.error("Error fetching configurations:", error);
      }
    };

    fetchConfig();
  }, [adminWalletAddress]);

  // Fetch data for all tabs
  useEffect(() => {
    const fetchData = async () => {
      try {
        const competitionsResponse = await axios.get(
          `${API_BASE_URL}/competitions`
        );
        const usersResponse = await axios.get(`${API_BASE_URL}/api/users`);
        const transactionsResponse = await axios.get(
          `${API_BASE_URL}/api/transactions`
        );

        setCompetitions(competitionsResponse.data);
        setUsers(usersResponse.data);
        setTransactions(transactionsResponse.data);

        console.log("Competitions data:", competitionsResponse.data);
        console.log("Users data:", usersResponse.data);
        console.log("Transactions data:", transactionsResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Fetch entries for competitions
  useEffect(() => {
    const fetchEntries = async () => {
      try {
        const entriesPromises = competitions.map((competition) =>
          axios.get(`${API_BASE_URL}/competitions/${competition.id}/entries`)
        );

        const entriesResponses = await Promise.all(entriesPromises);
        const allEntries = entriesResponses.flatMap(
          (response) => response.data
        );

        setEntries(allEntries);
        console.log("Entries data:", allEntries);
      } catch (error) {
        console.error("Error fetching entries:", error);
      }
    };

    if (competitions.length > 0) {
      fetchEntries();
    }
  }, [competitions]);

  // Fetch global jackpot data for users
  useEffect(() => {
    const fetchUserJackpotData = async () => {
      try {
        const promises = users.map((user) =>
          axios.get(
            `${API_BASE_URL}/api/users/${user.wallet_address}/global-jackpot`
          )
        );

        const responses = await Promise.all(promises);
        const jackpotData = responses.reduce((acc, response, index) => {
          acc[users[index].wallet_address] = response.data.tickets || 0;
          return acc;
        }, {});

        setUserJackpotData(jackpotData);
      } catch (error) {
        console.error("Error fetching user jackpot data:", error);
      }
    };

    if (users.length > 0) {
      fetchUserJackpotData();
    }
  }, [users]);

  // Fetch influencer list
  useEffect(() => {
    const fetchInfluencerList = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/get-influencer-list`,
          {
            params: {
              user_id: adminWalletAddress,
            },
          }
        );

        const influencers = Array.isArray(response.data.influencers)
          ? response.data.influencers
          : [];
        setInfluencerList(influencers);
      } catch (error) {
        console.error("Error fetching influencer list:", error);
        setInfluencerList([]); // Default to empty array on error
      }
    };

    if (adminWalletAddress) {
      fetchInfluencerList();
    }
  }, [adminWalletAddress]);

  // Handle sorting
  const handleSort = (column) => {
    let ascending = sortConfig.key === column ? !sortConfig.ascending : true;
    setSortConfig({ key: column, ascending });
  };

  // Helper function for sorting
  const sortData = (data, column, ascending) => {
    const sortedData = [...data].sort((a, b) => {
      if (a[column] < b[column]) return ascending ? -1 : 1;
      if (a[column] > b[column]) return ascending ? 1 : -1;
      return 0;
    });
    return sortedData;
  };

  // Filter and sort data
  const filteredCompetitions = sortData(
    competitions.filter((comp) =>
      comp.token_name.toLowerCase().includes(searchCompetitions.toLowerCase())
    ),
    sortConfig.key,
    sortConfig.ascending
  );

  const filteredUsers = sortData(
    users.filter((user) =>
      user.wallet_address.toLowerCase().includes(searchUsers.toLowerCase())
    ),
    sortConfig.key,
    sortConfig.ascending
  );

  const filteredTransactions = sortData(
    transactions.filter((transaction) => {
      const userId = transaction.user_id
        ? transaction.user_id.toString().toLowerCase()
        : "system";
      return userId.includes(searchTransactions.toLowerCase());
    }),
    sortConfig.key,
    sortConfig.ascending
  );

  // Calculate total tickets sold and unique participants for each competition
  const getTotalTicketsAndParticipants = (competitionId) => {
    const competitionEntries = entries.filter(
      (entry) => entry.competition_id === competitionId
    );
    const totalTicketsSold = competitionEntries.reduce(
      (acc, entry) => acc + entry.ticket_count,
      0
    );
    const uniqueParticipants = new Set(
      competitionEntries.map((entry) => entry.user_id)
    ).size;

    return {
      totalTicketsSold,
      uniqueParticipants,
    };
  };

  // Handle tab change
  const handleChangeTab = (value) => {
    setActiveTab(value);
  };

  // Handle fee configuration changes
  const handleFeeChange = (e) => {
    const { name, value } = e.target;
    setFeeConfig((prevConfig) => ({
      ...prevConfig,
      [name]: value,
    }));
  };

  // Handle fee configuration submission
  const handleFeeSubmit = async (e) => {
    e.preventDefault();
    setConfigMessage("");

    if (!adminWalletAddress) {
      setConfigMessage("Admin wallet address not available.");
      return;
    }

    try {
      // Update fee configurations in the backend
      const keys = [
        "PROTOCOL_FEE_PERCENTAGE",
        "CREATOR_FEE_PERCENTAGE",
        "GLOBAL_JACKPOT_FEE_PERCENTAGE",
        "INFLUENCER_FEE_PERCENTAGE",
        "MIN_JACKPOT_MULTIPLE",
      ];

      for (const key of keys) {
        await axios.post(`${API_BASE_URL}/admin/config`, {
          key,
          value: feeConfig[key],
          user_id: adminWalletAddress,
        });
      }

      // Call backend to update or initialize protocol config
      const response = await axios.post(
        `${API_BASE_URL}/admin/update-protocol-config`,
        {
          protocol_fee_percentage: parseInt(feeConfig.PROTOCOL_FEE_PERCENTAGE),
          creator_fee_percentage: parseInt(feeConfig.CREATOR_FEE_PERCENTAGE),
          global_jackpot_fee_percentage: parseInt(
            feeConfig.GLOBAL_JACKPOT_FEE_PERCENTAGE
          ),
          influencer_fee_percentage: parseInt(
            feeConfig.INFLUENCER_FEE_PERCENTAGE
          ),
          min_jackpot_multiple: parseInt(feeConfig.MIN_JACKPOT_MULTIPLE),
          user_id: adminWalletAddress,
        }
      );

      if (response.data.success) {
        setConfigMessage("Fee configurations updated successfully.");
      } else {
        setConfigMessage("Failed to update fee configurations.");
      }
    } catch (error) {
      console.error("Error updating fee configurations:", error);
      setConfigMessage("Failed to update fee configurations.");
    }
  };

  // Handle global jackpot configuration submission
  const handleJackpotSubmit = async (e) => {
    if (e) e.preventDefault();
    setJackpotMessage("");

    if (!adminWalletAddress) {
      setJackpotMessage("Admin wallet address not available.");
      return;
    }

    // Convert inputs
    const convertedPrice = parseFloat(jackpotConfig.price_per_ticket); // Ensure it's a number
    const convertedEndTime = Math.floor(
      new Date(jackpotConfig.end_time).getTime() / 1000
    ); // Convert to Unix timestamp in seconds

    console.log("Submitting Global Jackpot:", {
      price_per_ticket: convertedPrice,
      end_time: convertedEndTime,
    });

    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/initialize-global-jackpot`,
        {
          price_per_ticket: convertedPrice,
          end_time: convertedEndTime,
          user_id: adminWalletAddress,
        }
      );

      if (response.data.success) {
        setJackpotMessage("Global jackpot initialized successfully.");
      } else {
        setJackpotMessage("Failed to initialize global jackpot.");
      }
    } catch (error) {
      console.error("Error initializing global jackpot:", error);
      setJackpotMessage("Failed to initialize global jackpot.");
    }
  };

  // Handle influencer list management
  const handleAddInfluencer = () => {
    if (newInfluencerPublicKey) {
      setInfluencerList((prevList) => [...prevList, newInfluencerPublicKey]);
      setNewInfluencerPublicKey("");
    }
  };

  const handleRemoveInfluencer = (pubkey) => {
    setInfluencerList((prevList) => prevList.filter((key) => key !== pubkey));
  };

  const handleInfluencerListSubmit = async (e) => {
    e.preventDefault();
    setInfluencerMessage("");

    if (!adminWalletAddress) {
      setInfluencerMessage("Admin wallet address not available.");
      return;
    }

    try {
      // Send the updated influencer list to the backend
      const response = await axios.post(
        `${API_BASE_URL}/admin/update-influencer-list`,
        {
          new_influencers: influencerList,
          user_id: adminWalletAddress,
        }
      );

      // Handle the response
      if (response.data.success) {
        setInfluencerMessage("Influencer list updated successfully.");
      } else {
        setInfluencerMessage("Failed to update influencer list.");
      }
    } catch (error) {
      console.error("Error updating influencer list:", error);
      setInfluencerMessage("Failed to update influencer list.");
    }
  };

  const validateInputs = () => {
    if (!jackpotConfig.price_per_ticket) {
      setJackpotMessage("Price per ticket is required.");
      return false;
    }

    if (
      isNaN(jackpotConfig.price_per_ticket) ||
      jackpotConfig.price_per_ticket <= 0
    ) {
      setJackpotMessage("Price per ticket must be a positive number.");
      return false;
    }

    if (!jackpotConfig.end_time) {
      setJackpotMessage("End time is required.");
      return false;
    }

    const now = new Date();
    const selectedTime = new Date(jackpotConfig.end_time);

    if (selectedTime <= now) {
      setJackpotMessage("End time must be in the future.");
      return false;
    }

    setJackpotMessage(""); // Clear any previous error messages
    return true;
  };

  const handleDistributeInfluencerFees = async () => {
    try {
      // Get the admin wallet address
      const adminWalletAddress = publicKey?.toBase58();
      if (!adminWalletAddress) {
        setInfluencerMessage("Please connect your admin wallet.");
        return;
      }

      // Make the admin API call
      const response = await axios.post(
        `${API_BASE_URL}/admin/distribute-influencer-fees`,
        {
          user_id: adminWalletAddress,
        }
      );

      if (response.data.success) {
        setInfluencerMessage("Influencer fees distributed successfully.");
      } else {
        setInfluencerMessage("Failed to distribute influencer fees.");
      }
    } catch (error) {
      console.error("Error distributing influencer fees:", error);
      setInfluencerMessage(
        "An error occurred while distributing influencer fees."
      );
    }
  };

  // If adminWalletAddress is not available or not an admin, show a message
  if (!adminWalletAddress || !isAdmin) {
    return (
      <AdminPageWrapper>
        <AdminWindow>
          <WindowHeaderStyled>
            <span>AdminDashboard.exe</span>
            <Button onClick={() => navigate(-1)}>X</Button>
          </WindowHeaderStyled>
          <WindowContent>
            <p>Please connect your admin wallet to access the dashboard.</p>
          </WindowContent>
        </AdminWindow>
      </AdminPageWrapper>
    );
  }

  return (
    <AdminPageWrapper>
      <AdminWindow>
        <WindowHeaderStyled>
          <span>AdminDashboard.exe</span>
          <Button onClick={() => navigate(-1)}>X</Button>
        </WindowHeaderStyled>
        <WindowContent>
          <Tabs value={activeTab} onChange={(value) => handleChangeTab(value)}>
            <Tab value={0}>Competitions</Tab>
            <Tab value={1}>Users</Tab>
            <Tab value={2}>Transactions</Tab>
            <Tab value={3}>Settings</Tab>
          </Tabs>

          <TabBody>
            {activeTab === 0 && (
              <GroupBox label="Competitions">
                <CenteredTextInput
                  placeholder="Search Competitions..."
                  value={searchCompetitions}
                  onChange={(e) => setSearchCompetitions(e.target.value)}
                />
                <ScrollableTable>
                  <StyledTable>
                    <TableHead>
                      <TableRow>
                        <TableHeadCell onClick={() => handleSort("id")}>
                          ID
                        </TableHeadCell>
                        <TableHeadCell onClick={() => handleSort("token_name")}>
                          Name
                        </TableHeadCell>
                        <TableHeadCell onClick={() => handleSort("status")}>
                          Status
                        </TableHeadCell>
                        <TableHeadCell onClick={() => handleSort("prize_pool")}>
                          Prize Pool
                        </TableHeadCell>
                        <TableHeadCell onClick={() => handleSort("createdAt")}>
                          Creation Time
                        </TableHeadCell>
                        <TableHeadCell onClick={() => handleSort("end_time")}>
                          End Time
                        </TableHeadCell>
                        <TableHeadCell
                          onClick={() => handleSort("tickets_sold")}
                        >
                          Tickets Sold
                        </TableHeadCell>
                        <TableHeadCell
                          onClick={() => handleSort("participants")}
                        >
                          Participants
                        </TableHeadCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredCompetitions.map((competition) => {
                        const { totalTicketsSold, uniqueParticipants } =
                          getTotalTicketsAndParticipants(competition.id);

                        return (
                          <TableRow key={competition.id}>
                            <TableDataCell>{competition.id}</TableDataCell>
                            <TableDataCell>
                              {competition.token_name}
                            </TableDataCell>
                            <TableDataCell>{competition.status}</TableDataCell>
                            <TableDataCell>
                              {Number(competition.prize_pool).toFixed(2)} SOL
                            </TableDataCell>
                            <TableDataCell>
                              {new Date(
                                competition.createdAt
                              ).toLocaleString() || "N/A"}
                            </TableDataCell>
                            <TableDataCell>
                              {new Date(
                                competition.end_time
                              ).toLocaleString() || "N/A"}
                            </TableDataCell>
                            <TableDataCell>{totalTicketsSold}</TableDataCell>
                            <TableDataCell>{uniqueParticipants}</TableDataCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </StyledTable>
                </ScrollableTable>
              </GroupBox>
            )}
            {activeTab === 1 && (
              <GroupBox label="Users">
                <CenteredTextInput
                  placeholder="Search Users..."
                  value={searchUsers}
                  onChange={(e) => setSearchUsers(e.target.value)}
                />
                <ScrollableTable>
                  <StyledTable>
                    <TableHead>
                      <TableRow>
                        <TableHeadCell onClick={() => handleSort("id")}>
                          ID
                        </TableHeadCell>
                        <TableHeadCell
                          onClick={() => handleSort("wallet_address")}
                        >
                          Wallet Address
                        </TableHeadCell>
                        <TableHeadCell onClick={() => handleSort("balance")}>
                          Balance
                        </TableHeadCell>
                        <TableHeadCell
                          onClick={() => handleSort("global_jackpot_tickets")}
                        >
                          Global Jackpot Tickets
                        </TableHeadCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredUsers.map((user) => (
                        <TableRow key={user.id}>
                          <TableDataCell>{user.id}</TableDataCell>
                          <TableDataCell>{user.wallet_address}</TableDataCell>
                          <TableDataCell>
                            {Number(user.balance).toFixed(2)} SOL
                          </TableDataCell>
                          <TableDataCell>
                            {userJackpotData[user.wallet_address] || 0}
                          </TableDataCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </StyledTable>
                </ScrollableTable>
              </GroupBox>
            )}
            {activeTab === 2 && (
              <GroupBox label="Transactions">
                <CenteredTextInput
                  placeholder="Search Transactions (User ID, TxID)..."
                  value={searchTransactions}
                  onChange={(e) => setSearchTransactions(e.target.value)}
                />
                <ScrollableTable>
                  <StyledTable>
                    <TableHead>
                      <TableRow>
                        <TableHeadCell onClick={() => handleSort("id")}>
                          ID
                        </TableHeadCell>
                        <TableHeadCell onClick={() => handleSort("user_id")}>
                          User ID
                        </TableHeadCell>
                        <TableHeadCell onClick={() => handleSort("amount")}>
                          Amount
                        </TableHeadCell>
                        <TableHeadCell onClick={() => handleSort("type")}>
                          Type
                        </TableHeadCell>
                        <TableHeadCell onClick={() => handleSort("txID")}>
                          Transaction ID
                        </TableHeadCell>

                        <TableHeadCell onClick={() => handleSort("status")}>
                          Status
                        </TableHeadCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredTransactions.map((transaction) => (
                        <TableRow key={transaction.id}>
                          <TableDataCell>{transaction.id}</TableDataCell>
                          <TableDataCell>
                            {transaction.user_id || "System"}
                          </TableDataCell>
                          <TableDataCell>
                            {!isNaN(parseFloat(transaction.amount))
                              ? `${parseFloat(transaction.amount).toFixed(
                                  2
                                )} SOL`
                              : "N/A"}
                          </TableDataCell>
                          <TableDataCell>{transaction.type}</TableDataCell>
                          <TableDataCell>
                            {transaction.txID ? (
                              <a
                                href={`https://solscan.io/tx/${transaction.txID}?cluster=devnet`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {transaction.txID.slice(0, 10)}...
                              </a>
                            ) : (
                              "N/A"
                            )}
                          </TableDataCell>

                          <TableDataCell>
                            {transaction.status || "Unknown"}
                          </TableDataCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </StyledTable>
                </ScrollableTable>
              </GroupBox>
            )}

            {activeTab === 3 && (
              <GroupBox label="Settings">
                {/* Update Fee Configurations Section */}
                <GroupBox label="Update Fee Configurations">
                  <form onSubmit={handleFeeSubmit}>
                    <FormRow>
                      <label>Protocol Fee Percentage:</label>
                      <TextInput
                        name="PROTOCOL_FEE_PERCENTAGE"
                        value={feeConfig.PROTOCOL_FEE_PERCENTAGE}
                        onChange={handleFeeChange}
                        fullWidth
                      />
                    </FormRow>
                    <FormRow>
                      <label>Creator Fee Percentage:</label>
                      <TextInput
                        name="CREATOR_FEE_PERCENTAGE"
                        value={feeConfig.CREATOR_FEE_PERCENTAGE}
                        onChange={handleFeeChange}
                        fullWidth
                      />
                    </FormRow>
                    <FormRow>
                      <label>Global Jackpot Fee Percentage:</label>
                      <TextInput
                        name="GLOBAL_JACKPOT_FEE_PERCENTAGE"
                        value={feeConfig.GLOBAL_JACKPOT_FEE_PERCENTAGE}
                        onChange={handleFeeChange}
                        fullWidth
                      />
                    </FormRow>
                    <FormRow>
                      <label>Influencer Fee Percentage:</label>
                      <TextInput
                        name="INFLUENCER_FEE_PERCENTAGE"
                        value={feeConfig.INFLUENCER_FEE_PERCENTAGE}
                        onChange={handleFeeChange}
                        fullWidth
                      />
                    </FormRow>
                    <FormRow>
                      <label>Minimum Jackpot Multiple:</label>
                      <TextInput
                        name="MIN_JACKPOT_MULTIPLE"
                        value={feeConfig.MIN_JACKPOT_MULTIPLE}
                        onChange={handleFeeChange}
                        fullWidth
                      />
                    </FormRow>
                    <CenteredButton type="submit">Update Fees</CenteredButton>
                    {configMessage && <p>{configMessage}</p>}
                  </form>
                </GroupBox>

                <SectionSeparator />

                {/* Global Jackpot Section */}
                <GroupBox label="Global Jackpot">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleJackpotSubmit();
                    }}
                  >
                    <FormRow>
                      <label>Price Per Ticket (SOL):</label>
                      <TextInput
                        name="price_per_ticket"
                        value={jackpotConfig.price_per_ticket}
                        onChange={(e) =>
                          setJackpotConfig((prev) => ({
                            ...prev,
                            price_per_ticket: e.target.value,
                          }))
                        }
                        fullWidth
                      />
                    </FormRow>
                    <FormRow>
                      <label>End Time:</label>
                      <TextInput
                        type="datetime-local"
                        name="end_time"
                        value={jackpotConfig.end_time}
                        onChange={(e) =>
                          setJackpotConfig((prev) => ({
                            ...prev,
                            end_time: e.target.value, // Store the raw value from the input
                          }))
                        }
                        fullWidth
                      />
                    </FormRow>
                    <CenteredButton
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent default submission
                        if (validateInputs()) {
                          handleJackpotSubmit(e); // Pass the event object to handleJackpotSubmit
                        }
                      }}
                    >
                      Initialize Global Jackpot
                    </CenteredButton>

                    {jackpotMessage && <p>{jackpotMessage}</p>}
                  </form>
                </GroupBox>

                <SectionSeparator />

                {/* Manage Influencers Section */}
                <GroupBox label="Manage Influencers">
                  <form onSubmit={handleInfluencerListSubmit}>
                    <FormRow>
                      <label>New Influencer Public Key:</label>
                      <TextInput
                        value={newInfluencerPublicKey}
                        onChange={(e) =>
                          setNewInfluencerPublicKey(e.target.value)
                        }
                        fullWidth
                      />
                    </FormRow>
                    <ButtonRow>
                      <Button type="button" onClick={handleAddInfluencer}>
                        Add Influencer
                      </Button>
                      <Button type="submit">Update Influencer List</Button>
                    </ButtonRow>
                    {influencerMessage && <p>{influencerMessage}</p>}
                  </form>

                  <GroupBox>
                    {influencerList.map((influencer, index) => {
                      // Ensure each influencer is rendered correctly
                      const displayValue =
                        typeof influencer === "string" ? influencer : "Unknown";
                      return (
                        <CenteredRow key={index}>
                          <p>{displayValue}</p>
                          <Button
                            onClick={() => handleRemoveInfluencer(displayValue)}
                          >
                            Remove
                          </Button>
                        </CenteredRow>
                      );
                    })}
                  </GroupBox>

                  <SectionSeparator />
                  <p>
                    Influencer Fee Account Balance:{" "}
                    {influencerFeeAccountBalance} SOL
                  </p>
                  <CenteredButton onClick={handleDistributeInfluencerFees}>
                    Distribute Influencer Fees
                  </CenteredButton>
                  {influencerMessage && <p>{influencerMessage}</p>}
                </GroupBox>
              </GroupBox>
            )}
          </TabBody>
        </WindowContent>
      </AdminWindow>
    </AdminPageWrapper>
  );
};

export default DashboardPage;
